//
// Ant Design default variables styles overwrite
//

@primary-color: #1f1f1f; // primary color for all components
@link-color: rgba(0, 0, 0, 0.75); // link color
@success-color: #b7eb8f; // success state color
@warning-color: #ffe58f; // warning state color
@error-color: #c00; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 1); // heading text color
@text-color: lighten(@primary-color, 5%); // major text color
@text-color-secondary: lighten(@primary-color, 15%); // secondary text color
@disabled-color: lighten(@primary-color, 35%); // disable state color
@border-radius-base: 5px; // major border radius
@border-color-base: rgba(102,110,132,.2); // major border color
@box-shadow-base: none; // major shadow for layers
@component-loader-duration: 150; //ms
@default-color: #eaeff4;
// ------------------------------------------------------------------------ //
// ------------------------------------------------------------------------ //
//
// AvePDF variables
//
// ------------------------------------------------------------------------ //
// ------------------------------------------------------------------------ //
/*===============================
========== 00 Google Fonts Local ==========
===============================*/
html, body {
    height: 100%;
    width: 100%;
}

body.avepdf {
    background-color: #ffffff;
    color: @text-color;
}

* {
    -webkit-overflow-scrolling: touch;
}

:root.theme-switching *,
:root.theme-switching ::before,
:root.theme-switching ::after {
}

#root {
    height: 100%;
}

.async-svg-container {
    display: block;

    svg {
        width: 100%;
        height: 100%;
    }
}

.avepdf {
    /*===============================
========== 01 General ==========
===============================*/
    a {
        color: #1D7C76;

        &:hover,
        &:focus {
            color: #1D7C76;
            text-decoration: none;
        }
    }

    a:focus,
    a:active,
    button::-moz-focus-inner,
    input[type="reset"]::-moz-focus-inner,
    input[type="button"]::-moz-focus-inner,
    input[type="submit"]::-moz-focus-inner,
    select::-moz-focus-inner,
    input[type="file"] > input[type="button"]::-moz-focus-inner {
        border: 0;
        outline: none !important;
    }

    .vmiddle {
        display: flex;
        align-items: center;
    }

    svg {
        path {
        }
    }
    /*===============================
========== 02. Typography ==========
===============================*/


    h1 small,
    h2 small,
    h3 small,
    h4 small,
    h5 small,
    h6 small,
    .h1 small,
    .h2 small,
    .h3 small,
    .h4 small,
    .h5 small,
    .h6 small,
    h1 .small,
    h2 .small,
    h3 .small,
    h4 .small,
    h5 .small,
    h6 .small,
    .h1 .small,
    .h2 .small,
    .h3 .small,
    .h4 .small,
    .h5 .small,
    .h6 .small {
        font-weight: 500;
    }

    h1 a,
    h2 a,
    h3 a,
    h4 a,
    h5 a,
    h6 a {
        color: inherit;
    }

    .text-muted {
        color: #b0bdd2;
    }

    .text-notransform {
        text-transform: none;
    }

    .ant-btn {

        &.ant-btn-buy {
            background-color: @primary-color;
            border-color: @primary-color;
            color: #fff;
            padding: .6em 1.6em !important;
            font-weight: 700;
            border-radius: 5px;
            border: 1px solid transparent;

            &:hover {
                opacity: 0.8;
            }
        }

        .ant-btn-disabled {
            background-color: fade(@disabled-color, 5%) !important;
            color: fade(@primary-color, 25%) !important;
            border-color: fade(@disabled-color, 25%) !important;

            svg {
                path {
                    fill: fade(@primary-color, 25%) !important;
                }
            }

            img {
                opacity: .35;
            }
        }

        .btn-icon {
            width: 28px;
            height: auto;
        }

        &.ant-btn-primary {
            &[disabled] {
                .ant-btn-disabled();


                &:hover {
                    .ant-btn-disabled();
                }
            }
        }

        &[disabled] {
            .ant-btn-disabled();


            &:hover {
                .ant-btn-disabled();
            }
        }

        &.ant-btn-icon-only {
            width: auto;
        }

        &.download-action-button,
        &.upload-more {
            color: @primary-color !important;
            border-width: 1px;
            border-color: @primary-color;
            background: #fff;

            &.white-action-button {
                color: @primary-color;
                border-color: @primary-color;

                svg path {
                    fill: @primary-color;
                }
            }

            .download-icon-svg {
                vertical-align: top;
                width: 48px;

                @media (max-width: 575px) {
                    margin: auto;
                }

                path {
                    fill: @primary-color;
                }
            }

            svg path {
                fill: @primary-color;
            }

            &.delete-file-btn {
                display: block;
                width: 100%;
                margin-top: 5px;

                p {
                    margin: 0;
                }
            }

            &:hover, &:focus {
                border-color: @primary-color !important;
                background: #fff !important;
            }
        }

        &.ant-btn-danger {
            background: #ff4d4f !important;
            border-color: #ff4d4f !important;
        }

        &.ant-btn-success {
            background: #629722 !important;
            border-color: #629722 !important;
        }
    }
    /*========== Lead Paragraph ==========*/
    .lead {

        &.lead-small {
            font-size: 1.16em;
        }

        &.lead-medium {
            font-size: 1.4em;
        }

        &.lead-xbig {
            font-size: 1.8em;
        }

        &.lead-big {
            font-size: 2em;
        }
    }
    /*========== Special Headings ==========*/
    .title-large {
        font-size: 5.143em;
    }

    .title-medium {
        font-size: 4.571em;
    }

    .title-xmedium {
        font-size: 3.857em;
    }

    .title-normal {
        font-size: 2.8em;
    }

    .title-xnormal {
        font-size: 2.4em;
    }

    .title-small {
        font-size: 2em;
    }

    .title-exsmall {
        font-size: 1.429em;
    }

    .title-header {
        font-size: 1.764em;
    }
    /*========== Font Weight ==========*/
    .xlight {
        font-weight: 100 !important;
    }

    .light {
        font-weight: 300 !important;
    }

    .sbold {
        font-weight: 600 !important;
    }

    .bold {
        font-weight: 700 !important;
    }

    .ubold {
        font-weight: 900 !important;
    }
    /*====================================
========== Main Wrapper ==========
======================================*/
    // Main Content
    .main-content {
        /* background-color: #fafdff; */
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 3;
    }

    .main-content-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }
    /*===============================
========== AvePDF Header ==========
===============================*/
    /*====== Header ======*/
    .header {
        position: relative;
        padding: 5px 15px;
    }
    /*=== New main header ===*/
    .ave-header {
        padding: 10px 0;
        border-bottom: 1px solid rgba(102,110,132,.2);

        .logo-wrapper {
            margin-bottom: 0;
            padding: 0;
            width: 161px;
            height: 26px;

            svg {
                display: block;
                width: 161px;
                height: 26px;
            }
        }

        .navbar {
            .hamburger-menu-button {
                height: 54px;
                margin: 0;

                &.active {
                    border: none;
                }
            }

            .header-hamburger-menu {
                display: none;
                position: relative;
                z-index: initial;
                right: 0;
                margin-top: 0;

                &.show {
                    display: block;
                }

                ul {
                    li {
                        margin-bottom: 0;

                        @media (max-width: 1199.98px) {
                            margin: 0;
                        }
                    }
                }
            }

            a {
                color: #666E84;

                &:hover,
                &:focus,
                &:active {
                    color: #1D7C76;
                }
            }

            .navbar-nav {

                @media (max-width: 1199.98px) {
                    padding: 0 15px 15px;
                    border-radius: 5px;
                    background: #fff;
                    border: 1px solid #e8e9ec;
                    margin-top: 0;
                }

                .ave-main-menu-user {
                    @media (max-width: 1199.98px) {
                        width: 100%;
                    }
                }

                .nav-item {
                    font-size: 14px;
                    margin: 0 0 0 20px;


                    @media (max-width: 1199.98px) {
                        margin: 0;
                        display: block;

                        .nav-link {
                            padding: 1rem;
                        }
                    }
                }

                li > a {
                    @media (max-width: 1199.98px) {
                        line-height: 1.375rem;
                    }
                }

                .selected-language {
                    font-weight: 600;
                    display: block;
                    border-radius: 5px;
                    padding: 9px 15px;
                    border: 2px solid #e8e9ec;
                    color: #666E84;
                    text-transform: uppercase;

                    svg {
                        vertical-align: initial;
                    }
                }

                .ave-main-menu-user {
                    .user-buttons {
                        .ant-btn {
                            font-weight: 600;
                            color: #1D7C76 !important;
                            padding: 5px 15px !important;
                            border-radius: 5px;
                            background-color: rgba(76,164,159,.2);
                            margin-right: 20px;
                            border: none;

                            @media (max-width: 1199.98px) {
                                margin: 15px 0;
                                width: 49%;

                                &:first-child {
                                    margin-right: 2%;
                                }
                            }

                            @media (max-width: 767.98px) {
                                display: block;
                                width: 100%;
                                margin: 15px 0;
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                color: #fff !important;
                                background-color: #1D7C76;
                                border: none;
                            }

                            svg {
                                path {
                                    fill: #1D7C76;
                                }
                            }
                        }
                    }
                }
            }

            &.ave-navbar {
                a {
                    color: @primary-color;
                    font-weight: 600;

                    &:hover,
                    &focus {
                        color: #1D7C76;
                    }
                }

                .navbar-nav {
                    padding: 0;
                    border-radius: 0;
                    background: transparent;
                    border: none;
                    margin-top: 0;

                    .nav-link {
                        @media (max-width: 991.98px) {
                            border-bottom: none !important;
                            white-space: nowrap;
                            padding: 0.6rem 1rem;
                            white-space: nowrap;
                            background: rgba(234,234,250,.3);
                            margin-bottom: 2px;
                            border-radius: 5px;
                        }
                    }
                }

                .megamenu {
                    position: static;
                }

                .megamenu .dropdown-menu {
                    width: 100%;
                    background: #ffffff;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    top: calc(100% + 13px);
                    padding: 0;
                    border: 1px solid rgba(102,110,132,.2);
                    border-top: transparent;
                    box-shadow: 0 40px 60px -20px rgba(102,110,132,.15);

                    @media (max-width: 991.98px) {
                        border-top-left-radius: @border-radius-base;
                        border-top-right-radius: @border-radius-base;
                        border-top: 1px solid rgba(102,110,132,.2);
                    }

                    .megamenu-tools {
                        position: relative;
                        width: 100%;

                        .megamenu-tools-category {
                            width: 100%;
                            margin-bottom: 15px;

                            .megamenu-category-title {
                                text-transform: capitalize;
                                font-size: 1.1em;
                                font-weight: 600;
                                border-bottom: 1px solid rgba(102,110,132,.2);
                                padding-bottom: 10px;
                                margin-bottom: 10px;
                                color: lighten(@text-color, 40%);
                            }

                            ul {
                                font-size: 14px;
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                column-count: initial;
                                display: grid;
                                grid-template-columns: repeat(4, 1fr);
                                grid-auto-flow: row;
                                gap: 2px;
                                align-items: center;

                                @media (max-width: 1199.98px) {
                                    grid-template-columns: repeat(3, 1fr);
                                }

                                @media (max-width: 991.98px) {
                                    grid-template-columns: repeat(2, 1fr);
                                }

                                @media (max-width: 575.98px) {
                                    grid-template-columns: repeat(1, 1fr);
                                }

                                li {
                                    display: inline-block;
                                    line-height: 1.4;
                                    padding: 0 5px 0 45px;
                                    width: 100%;
                                    position: relative;
                                    border: 1px solid transparent;
                                    border-radius: 5px;

                                    &.active {
                                        background: rgba(234,234,250,.08);
                                        border: 1px solid rgba(102,110,132,.2);
                                    }

                                    &:hover {
                                        background: rgba(234,234,250,.08);
                                        border: 1px solid rgba(102,110,132,.2);

                                        a {
                                            color: #1D7C76 !important;
                                        }
                                    }

                                    .tool-item-block-icon {
                                        display: inline-block;
                                        max-width: 32px;
                                        position: absolute;
                                        left: 5px;
                                        top: 50%;
                                        margin-top: -16px;
                                        padding: 4px;
                                    }

                                    a {
                                        font-weight: 400;
                                        display: block;
                                        padding: 12px 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .logo-wrapper {

        svg {

            path {
                fill: @primary-color !important;
            }
        }
    }

    .modal-logo {
        max-height: 32px;
        margin-right: 10px;
    }

    .theme-switch-wrapper {
        margin: 0;
        font-size: 14px;
    }

    .theme-switch {
        position: relative;
        margin-right: 10px;

        .btn {
            position: relative;
            padding: 0;
            margin: 0;
            border: none;

            &_background {
                width: 45px;
                height: 26px;
                border-radius: 50px;
                background: transparent;
                border: 3px solid @primary-color;
                cursor: pointer;


                .btn_face {
                    background: #1f1f1f;
                }

                &.color {
                    background: #1f1f1f;
                    border-color: #fff;

                    .btn_face {
                        background: #fff;
                    }
                }
            }

            &_face {
                position: absolute;
                top: 6px;
                left: 6px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: #1f1f1f;


                &.move {
                    transform: translateX(17px);
                }
            }
        }
        /* WIN10 LOADER ANIMATION*/
        @keyframes orbit {
            0% {
                transform: rotate(225deg);
                opacity: 1;
                animation-timing-function: ease-out;
            }

            7% {
                transform: rotate(345deg);
                animation-timing-function: linear;
            }

            30% {
                transform: rotate(455deg);
                animation-timing-function: ease-in-out;
            }

            39% {
                transform: rotate(690deg);
                animation-timing-function: linear;
            }

            70% {
                transform: rotate(815deg);
                opacity: 1;
                animation-timing-function: ease-out;
            }

            75% {
                transform: rotate(945deg);
                animation-timing-function: ease-out;
            }

            76% {
                transform: rotate(945deg);
                opacity: 0;
            }

            100% {
                transform: rotate(945deg);
                opacity: 0;
            }
        }
    }


    .header-container {
        font-size: 0.875em;

        a {
        }
    }
    /*====== AvePDF Auth Buttons ======*/
    .header .auth-buttons {

        .btn {
            font-weight: 600;
        }

        a {
            display: inline-block;
            color: @primary-color;
            background-color: #fff;
            border: none;
            outline: none;
            margin-right: 8px;
            padding: 7px 20px;
            border-radius: @border-radius-base;

            &:last-child {
                margin-right: 0;
            }
        }

        .login-button {
            border: 2px solid @primary-color;
        }

        .signup-button {
            color: #fff;
            background-color: @primary-color;
            border: 2px solid @primary-color;
        }
    }
    /*====== AvePDF Hamburger Menu ======*/
    .hamburger-menu-button {

        &:after,
        &:before,
        span {
            background-color: @primary-color;
        }

        &.active {
            border: 1px solid @border-color-base;
            border-bottom-color: #fff;
            border-radius: @border-radius-base @border-radius-base 0 0;

            &:before {
                transform: translateY(11px) rotate(135deg);
            }
        }

        &.active {
            &:after {
                transform: translateY(-9px) rotate(-135deg);
            }
        }

        &.active {
            span {
                transform: scale(0);
            }
        }
    }

    .header-hamburger {

        .logged-user-image {
            margin: 0 auto;
            display: block;
            margin-top: 10px;
        }
    }

    .header-hamburger-menu {

        @media (max-width: 768px) {
            position: relative;
            right: 0;
        }

        &.active {
            display: block;
        }

        .username {
            margin: 10px 0;
            text-align: center;
            color: @primary-color;
        }

        .logged-user-no-image {
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                margin-bottom: 15px;
            }
        }

        .login-button,
        .signup-button {
            color: #fff !important;
            background-color: @primary-color;
            border: none;
            border-radius: @border-radius-base;
        }

        .signup-button {
            color: #fff;
            background-color: #1D7C76;
        }

        .horizontal-borders {
            border: 1px solid #ededed;
            border-left: none;
            border-right: none;
            padding: 12px 20px 12px 0;
            cursor: pointer;

            a {
                color: @primary-color;
                width: 100%;
                display: inline-block;
            }
        }

        .icon-dropdown {
            cursor: pointer;
            max-width: 20px;
        }

        .pre-menu-icon,
        .main-menu-nav-icon {
            img {
                cursor: pointer;
                max-width: 20px;

                path {
                    fill: @primary-color !important;
                }
            }
        }
    }

    .ave-main-menu {
        border: 1px solid @border-color-base;
        border-radius: @border-radius-base 0 @border-radius-base @border-radius-base;


        @media (max-width: 768px) {
            margin-top: -10px;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            min-width: 100%;
        }

        &:before {
            content: "";
            position: absolute;
            width: 42px;
            height: 6px;
            background-color: white;
            top: -3px;
            right: 0
        }

        .ave-main-menu-user {
            border-bottom: 1px solid @border-color-base;

            .ave-main-menu-profile {
                padding: 0 5px;

                .logged-in-menu {
                    li {
                        &:last-child {
                            margin-bottom: 0;
                        }

                        .ant-btn {
                            color: @primary-color;
                            border: 1px solid @border-color-base;
                            padding: 10px 15px !important;

                            .ant-btn-disabled {
                                background-color: fade(@disabled-color, 5%);
                                color: fade(@primary-color, 25%);
                                border-color: fade(@disabled-color, 25%);

                                svg {
                                    path {
                                        fill: fade(@primary-color, 25%) !important;
                                    }
                                }

                                img {
                                    opacity: .5;
                                }
                            }

                            &[disabled] {
                                .ant-btn-disabled();


                                &:hover {
                                    .ant-btn-disabled();
                                }
                            }

                            &:hover,
                            &:focus {
                                background-color: rgba(160, 188, 222, 0.08);
                            }
                        }
                    }
                }

                .icon-dropdown {
                    margin-right: 15px;
                }
            }
        }

        .ave-main-menu-nav {
            font-size: 14px;
            font-weight: 500;

            .material-icons {
                vertical-align: middle;
                margin-right: 10px;
            }

            ul {

                li {

                    .ave-nav-link {
                        color: @primary-color !important;
                    }

                    &:hover,
                    &:focus {
                        background-color: rgba(160, 188, 222, 0.08);
                        cursor: pointer;
                    }

                    margin: 0;
                    padding: 0;
                    border-top: 1px solid @border-color-base;

                    .menu-item-dropdown {
                        border: 1px solid @border-color-base;
                        background: #fff;
                        position: absolute;
                        top: 0;
                        right: 100%;
                        margin-right: 10px;
                        border-radius: @border-radius-base;
                        width: max-content;

                        @media (max-width: 768px) {
                            position: relative;
                            right: 0;
                            width: 100%;
                            margin: 15px auto 0;
                        }

                        .menu-dropdown-list {
                            li {
                                position: relative;

                                .pre-menu-icon {
                                    width: 20px;
                                    margin-right: 15px;
                                    display: inline-block;

                                    svg {
                                    }
                                }

                                .dev-icon {
                                    width: 20px;
                                    margin-right: 10px;
                                    display: inline-block;

                                    &.docuvieware-icon {
                                        color: #0382d4 !important;

                                        svg {
                                            path {
                                                fill: #0382d4 !important;
                                            }
                                        }
                                    }
                                }

                                a {
                                    padding: 15px 30px;
                                    margin: 0;
                                    display: block;

                                    &.external-link {
                                        padding: 15px 60px 15px 15px;
                                    }
                                }
                            }

                            &.menu-language-list {
                                font-size: 13px;
                                padding: 15px;
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);

                                @media (max-width: 992px) {
                                    display: grid;
                                    grid-template-columns: repeat(2, 1fr);
                                }

                                li {
                                    border: none;
                                    margin: 2px 0;

                                    span {
                                        padding: 10px 15px;
                                        display: block;
                                        border-radius: @border-radius-base;
                                    }

                                    &:hover,
                                    &:focus {
                                        cursor: pointer;
                                        background-color: rgba(160, 188, 222, 0.08);
                                        border-radius: @border-radius-base;
                                    }


                                    &.active-lang {
                                        span {
                                            font-weight: 600;
                                            background-color: rgba(160, 188, 222, 0.08);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .ave-main-menu-theme-switch {
            border-top: 1px solid @border-color-base;
        }
    }

    .homepage-no-result-found {
        padding: 40px 0px;
        margin: auto;
        font-weight: 500;
    }

    .content-inner-container {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 36px;
    }
    // Language Bar notification
    .language-notification {
        margin: 0;
        color: #42a5f5;
        padding: 15px 0;
        z-index: 10;
        width: 100%;
        border-top: none;
        border-radius: 0;
        -webkit-border-radius: 0;
    }

    .language-notification.alert-dismissable .close,
    .language-notification.alert-dismissible .close {
        opacity: 0.6;
        right: 0;
    }

    .alert-out {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 100;
    }

    .pricing-button {
        background-color: #1D7C76;
        color: #fff;

        &:hover {
            color: #ffffff !important;
            opacity: .8;
        }
    }
    //Header notification
    .header-alert {
        background-color: @primary-color;
        color: #fff;
        border: none;
        border-bottom: 1px solid @border-color-base;
        border-radius: 0;
        font-size: 85%;

        .badge {
            font-size: 90%;
        }

        a {
            color: #fff;
            border-bottom: 1px dashed;
        }
    }
    /*========== Notification Bar ==========*/
    .notification-bar {
        display: block;
        position: relative;
        width: 100%;
        background: rgb(152, 88, 133);
        background: linear-gradient(90deg, rgba(152, 88, 133, 1) 0%, rgba(181, 53, 139, 1) 100%);
        padding: 10px 0;

        p {
            margin: 0;
            color: #fff;
            font-size: 14px;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }
    }
    /*===============================
========== AvePDF Main Content ==========
===============================*/
    .home-header {
        color: @primary-color;
    }

    .home-subheader {
        font-size: 17px;
        text-align: center;
        color: #3a485d;
        padding-top: 16px;
    }

    .tool-item-description {
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        color: @primary-color;
        padding: 0 5px;
    }

    .small-icons {
        .tool-item-icon {
            img {
                max-width: 72px;
            }
        }

        .tool-item-content {
            width: 130px;
            height: 130px;

            .tool-item-description {
                line-height: 0.7;

                span {
                    font-size: 11px;
                }
            }
        }
    }

    .language-overlay {
        height: 300px;
        width: 150px;
        overflow: auto;
    }

    .font-family-overlay {
        width: 225px;
        overflow: auto;
        height: 300px;

        .ant-dropdown-menu {
            margin: 0 !important;
        }
    }

    .language-title {
        font-size: 1.2em;
        font-weight: bold;
        margin: 0;
    }

    #dragAndDropArea {

        .selected-language {
            font-size: 1em;
            font-weight: 500;
        }
    }
    // Language Select
    .language-change-loader {
        width: 100%;
        height: 5px;
        overflow: hidden;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
    }

    .language-select {

        .custom-select {
            margin: 0;
            padding: 0 1.75rem 0rem 0;
            border: none;
            color: #354063;
            font-size: 0.857em;
            height: auto;
            line-height: 1.4;
            font-weight: 300;
            vertical-align: middle;
            width: 100%;
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }
        }

        .dropdown-toggle {
            padding: 0;
        }

        .dropdown-menu {
            border: 1px solid #354063 !important;
            -webkit-box-shadow: none;
            box-shadow: none;
            -webkit-border-radius: @border-radius-base;
            border-radius: @border-radius-base;
            padding: 0;

            li {
                border: none;
                padding: 0;
                font-size: 0.875rem;
            }
        }
    }
    /* Login & Sign up */
    .validation-summary-errors {
        margin-left: 0 !important;
        color: #ffffff !important;
        padding: 15px;
        margin-bottom: 30px;
        background-color: #ff0000;
        border: 2px solid #dd0000;
        -webkit-border-radius: @border-radius-base;
        border-radius: @border-radius-base;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 0.857em;
        }
    }

    .field-validation-error {
        display: inline-block;
        margin: 5px 0;
        font-size: 0.857em;
    }

    .social-login .col-sm-6 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .sign-form {
        .checkbox {
            margin: 0;
            display: inline-block;
        }

        .forgot-pass {
            min-height: 20px;
            text-align: end;
            line-height: 26px;
            float: right;
        }
    }
    //Stiky Viewer
    .dv-sticky-viewer {
        top: 20px;
        z-index: 990;
    }
    //Modal default
    .ant-modal-close-x {
        height: 35px;
        line-height: 35px;
        width: 35px;
    }

    .ant-modal-content {
        .ant-btn {
            &.ant-btn-primary {
            }
        }
    }
    // Inputs checkbox
    input[type='checkbox']:checked {
        filter: unset;
        -webkit-filter: invert(1) contrast(14) saturate(0);
    }

    .dv-main {
        input[type='checkbox']:checked {
            filter: unset;
            -webkit-filter: unset;
        }
    }

    @-moz-document url-prefix() {
        input[type='checkbox']:checked {
            filter: unset;
        }
    }
    //Fieldset
    .tool-field {
        margin: 0 15px 45px;
        padding: 40px 30px 40px 30px;
        border: 1px solid rgba(102,110,132,.2);
        border-radius: @border-radius-base;

        legend {
            display: inline-block;
            width: auto;
            padding: 0 10px;
            font-size: 18px;
            font-weight: bolder;
        }
    }
    /*===============================
========== 09. Tools ==========
===============================*/
    .avetool-preloader {
        display: block;
        margin: 15% auto;
        height: 100%;
        width: 100%;
        max-width: 256px;
        z-index: 5;

        path {
            fill: @primary-color;
        }

        @media (max-width: 575.98px) {
            max-width: 180px;
        }
    }


    #action {
        display: none;
    }

    #download {
        display: none;
    }

    .tool-action-wrapper {
        min-height: 256px;
    }

    .drag-and-drop-area {
        min-height: 256px;
        border-style: dashed;
        border-width: 2px;
        border-color: #dfe6ec;
        -webkit-border-radius: @border-radius-base;
        border-radius: @border-radius-base;
        overflow: hidden;
        position: relative;
    }

    .downloaded-wrapper {
        border-radius: @border-radius-base;
    }

    .proceed-after-unlock {
        margin-top: 20px;
    }
    /* Tools */
    .scroll-element {
        overflow-y: scroll;
        height: 35vw;
    }

    .tools-container {
        padding: 60px 0;
    }
    /* tooltip header item */
    .header-item-wrapper {
        position: relative;

        .ave-tooltip,
        .ave-tooltip-triangle {
            border: 2px solid rgba(102,110,132,.2);
        }
    }

    .header-tooltip-wrapper {
        left: 8px;
        top: 180%;

        .ave-tooltip {
            padding: 15px;
            text-align: center;
        }

        .ave-tooltip-triangle {
            height: 15px;
            width: 15px;
            top: -9px;
            -ms-transform: rotate(225deg);
            -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
        }
    }

    .input-number-tooltip-wrapper.active {
        opacity: 1 !important;
        visibility: visible;
    }
    /* SHARE BUTTON START */
    .embed-button-wrapper {
        &:hover, &:focus {
            background-color: @primary-color;

            .share-button-label {
                color: #fff;
            }
        }
    }

    .share-button-wrapper {
        position: absolute;
        bottom: 10px;
        left: 10px;

        .share-button-tooltip-wrapper {
            bottom: 125%;
            left: 0;

            .ave-tooltip-triangle {
                height: 7px;
                width: 7px;
                bottom: -4px;
                left: 10px;
            }
        }
    }

    .share-button {

        .share-button-icon-svg {

            path {
                fill: @primary-color;
                -webkit-transition: none !important;
                transition: none !important;
            }
        }

        &:hover,
        &.active {

            .share-button-label {
            }

            .share-button-icon-svg {

                path {
                }
            }
        }
    }

    .element-with-tooltip:hover .share-button-tooltip-wrapper {
        transition: visibility 0.6s, opacity 0.6s linear;
        -webkit-transition: visibility 0.6s, opacity 0.6s linear;
        transition-delay: 0.6s;
    }

    .share-socials {
        position: absolute;
        border: 1px solid #dce6f5;
        padding: 10px;
        border-radius: 5px;
        background-color: #fff;
        min-width: 200px;
        bottom: 125%;
        left: 0;
    }

    .share-socials-triangle {
        display: block;
        position: absolute;
        height: 7px;
        width: 7px;
        border-top: none !important;
        border-left: none !important;
        background: #fff;
        bottom: -4px;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        left: 10px;
        border: 1px solid #dce6f5;
    }

    .share-socials {

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                margin-right: 15px;

                &:first-child {
                    margin-left: 7px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .share-facebook svg path {
        fill: #4368b0;
    }

    .share-twitter svg path {
        fill: #1ca1f1;
    }

    .share-linkedin svg path {
        fill: #0077b5;
    }

    .share-email svg path {
        fill: #385682;
    }

    .share-social-icon-svg {
        width: 30px;
        cursor: pointer;
    }
    /* SHARE BUTTON END */
    /* Share Bar Start */
    .share-icon-bar {
        background: @primary-color;
        border-radius: @border-radius-base;
        border: 1px solid lighten(@primary-color, 35%);

        ul {

            li {
                color: white;
                background: @primary-color;

                &:hover {
                    background-color: lighten(@primary-color, 8%);
                }
            }
        }
    }

    @media (max-width: 576px) {
        .share-icon-bar {
            display: none;
        }
    }

    @keyframes zoominout {
        0% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1.2, 1.2);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    .share-icon-bar.share-pulse {
        animation: zoominout .5s infinite;
    }
    /* Share Bar End*/
    /* RENAME FILE MODAL */
    .rename-file-modal {
        .download-file-methods {
            .rename-download-file {
                width: 100%;
                border-radius: @border-radius-base;


                .ant-input-group-wrapper {
                    border-radius: @border-radius-base;

                    .ant-input-affix-wrapper {
                    }
                }


                .ant-btn:last-child {
                }
            }
        }
    }
    /* END RENAME FILE MODAL */
    /* EMBED BUTTON START */

    .embed-modal {
        margin: 0 15px;

        .ant-modal {
            max-width: 720px;
        }

        .ant-modal-content {
            padding: 50px;
            border-radius: @border-radius-base;
        }

        .ant-modal-header {
            padding: 25px 0;

            p {
                margin: 0;
            }
        }

        .embed-copy-message {
            position: absolute;
            right: 15px;
            top: -86px;

            span {
                font-size: .825em;
                background: #fff;
                border: 1px solid @border-color-base;
                padding: 15px;
                border-radius: @border-radius-base 0 @border-radius-base 0;
            }
        }

        .ant-modal-footer {
            padding: 25px 0;
        }

        .ant-modal-title {
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
        }

        .ant-modal-body {
            padding: 24px 0;
        }

        .ant-modal-body p {
            font-size: 1.2em;
            margin-bottom: 0;
        }

        .embed-script {
            resize: none;
            background-color: #fafbff;
            border: none;
            height: auto;
        }

        .copy-embed-button,
        .copy-embed-button:hover,
        .copy-embed-button:focus {
            color: #fff;
            border: none;
            background-color: @primary-color;
            padding: 13px 20px;
            height: auto;
        }
    }


    @media (max-width: 768px) {
        .embed-modal .ant-modal-content {
            padding: 30px 15px;
        }
    }

    @media (max-width: 376px) {
        .embed-modal .ant-modal {
            top: 15px;
        }
    }
    /* EMBED BUTTON END */
    .tool-item {
        text-align: center;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
        padding: 10px 0;
    }

    .coming-soon {
        display: block;
        position: absolute;
        top: -10px;
        right: -20px;
        text-transform: lowercase;
        background: rgb(51, 51, 51);
        color: #ffffff;
        padding: 2px 12px;
        border-radius: @border-radius-base;
        font-size: 12px;
        line-height: 20px;
    }

    .tool-item-content {
        width: 200px;
        height: 200px;
        margin: 0 auto;
        position: relative;
    }

    .tool-item-icon {
        position: relative;
        line-height: 1;

        img {
            width: 60%;
            padding: 11% 0 6% 0;
        }
    }

    .tool-item-description {
        text-align: center;

        span {
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin: 0;
            line-height: 1.2;
        }
    }
    /* Labels Tools*/
    .tool-label {
        position: absolute;
        top: -15px;
        right: -10px;
        padding: 2px 12px;
        background-color: #232b43;
        -webkit-border-radius: @border-radius-base;
        -moz-border-radius: @border-radius-base;
        border-radius: @border-radius-base;

        p {
            margin: 0;
            color: #ffffff;
            font-size: 0.675rem;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .tool-header-container {
        .tool-label {
            p {
                color: @text-color;
            }
        }
    }

    .megamenu-list-category {
        .beta-tool {
            .tool-label {
                p {
                    color: @text-color;
                }
            }
        }
    }
    /* Tools Info*/
    .tools-info-title {
    }

    .tools-info-title-wrapper {
        padding-bottom: 15px;

        .title-header {
            margin-bottom: 0;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
        }
    }

    .tools-box {
        -webkit-border-radius: @border-radius-base;
        -moz-border-radius: @border-radius-base;
        -o-border-radius: @border-radius-base;
        border-radius: @border-radius-base;
        padding-top: 90px;
        padding-bottom: 90px;
        position: relative;
        border: 1px dashed rgba(102,110,132,.8);
    }

    .tools-box-embedded {
        padding-top: 48px;
        padding-bottom: 120px;
        position: relative;
    }

    .embedded-power-by {
        display: inline-block;
        bottom: 20px;
        right: 20px;
        position: absolute;
        max-width: 180px;

        svg {
            path {
                fill: @text-color;
            }
        }
    }
    /* Upload Button */
    #files {
        display: none;
        /* do not display the actual file upload button */
    }

    #files-upload-action {
        display: none;
    }

    .thumbnail-part {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px 15px;
        padding: 45px 15px;
        text-align: center;
        border-radius: @border-radius-base;
        box-shadow: 0 8px 24px 0 #f1f5fc;
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

        .eqbox-wrapper {
            width: auto;
        }

        @media (max-width: 768px) {

            .eqbox-wrapper {
                width: 100%;
            }
        }
    }

    .pages-sortable {
        padding: 0;
    }

    .thumbnail-part:not(.ui-sortable-helper) {
        top: 0px !important;
    }

    .thumbnail-part:hover:not(.ui-sortable-helper) {
        /* background-color: #f2f8fc; */
        top: -5px !important;
        background-color: lighten(@primary-color, 85%);
    }

    .thumbnail-title {
        max-width: 250px;
        font-size: 0.875em;
        font-weight: 500;
        margin: 1em auto 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: @primary-color;
    }

    .thumbnail-number {
        margin: 0 0 -20px;
        font-size: 0.875em;
        color: @primary-color;
    }

    .unable-load-page-preview {
        display: flex;
        align-items: center;

        p {
            margin: 0;
        }
    }

    .thumbnail-image-wrapper,
    .unable-load-page-preview {
        height: 256px;
        width: 256px;
    }

    .thumbnail-empty-page {
        position: relative;

        &::before {
            background-color: rgb(255, 255, 255);
            content: " ";
            height: 256px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 180px;
            z-index: -1;
        }
    }

    .thumbnail-image-wrapper canvas,
    .unable-load-page-preview {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .thumbnail-image-wrapper img {
        border: 1px solid #dfe6ec;
    }

    #file-preview-pdf {
        text-align: center;
        padding: 0;
    }

    .thumbnail-icons-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        text-align: end;
        display: block;
        width: 100%;
        padding: 4px 6px !important;

        svg {
            width: 20px;
            height: 20px;
            vertical-align: text-bottom;
        }

        .thumbnail-close {
            svg {
                path {
                    fill: @text-color;
                }
            }
        }

        .ant-btn {
            padding: 4px 6px !important;
            min-width: auto;
        }

        .btn {
            color: @primary-color;
            padding: 4px 6px !important;
            min-width: auto;

            &:hover {
                background-color: transparent;
            }
        }
    }

    .extract-pdf-pages, .split-pdf {

        .thumbnail-icons-wrapper {

            svg {
                background: #d0e0ea !important;
                border-radius: @border-radius-base;
            }
        }
    }

    .thumbnail-close {
        color: #354063;
        background-color: transparent;
        position: absolute;
        right: -30px;
        top: 4px;
        padding: 4px 6px !important;
        min-width: auto;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        opacity: 0;

        img {
            width: 16px;
            height: 16px;
        }



        &:hover,
        &:focus {
            background-color: @primary-color;
        }
    }

    .thumbnail-select {
        cursor: pointer;
        font-size: 1.4em;
        color: #d0e0ea !important;
        background-color: transparent;
        min-width: auto;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently */

        svg {
            path {
                fill: #d0e0ea;
            }
        }
    }

    [id^='thumbnail-select-'] {
        svg {
            path {
                fill: inherit !important;
            }
        }
    }

    .thumbnail-part:hover {
        .thumbnail-close {
            right: 4px;
            opacity: 1;
        }
    }

    .thumbnail-rotation {
        position: absolute;
        top: 5px;
        width: 77px;
        left: 95px;
        border: 1px solid #dfe6ee;
        border-radius: @border-radius-base;
        text-align: center;
    }

    .thumbnail-rotation.invalid {
        color: red;
    }

    .extract-pdf-pages-options {
        i {
            width: 40px;
            height: 40px;
            font-size: 40px;
            margin-right: 15px;
            color: @primary-color;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .split-instructions,
    .split-instructions-mobile {
        margin-bottom: 20px;
    }

    .split-instructions-mobile {
        display: none;
    }

    .split-label {
        color: #ffffff;
    }

    .split-list-elem {
        margin: 0 5px;
        margin-bottom: 5px;
    }

    .ui-selectable-helper {
        position: absolute;
        z-index: 100;
        border: 1px dotted;
    }

    #splits {
        margin-bottom: 0;
    }

    .selectable-preview {
        &.split-selectable-preview {
            padding-top: 35px;
        }

        .ui-selecting {
            background: #f5f6f7;
        }

        .ui-selected-custom {
            background: lighten(@primary-color, 85%);
        }
    }

    .split-close {
        right: 0px;
        width: 24px;
        color: #354063;
        background-color: transparent;
        position: absolute;
        top: 2px;
        padding: 4px 6px !important;
        min-width: auto;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;

        i {
            &.material-icons {
                font-size: inherit;
            }
        }
    }

    .split-add {
        background: #1d7c76;
        border: none;
        padding: 6px 10px;
        color: #ffffff;
        border-radius: @border-radius-base;
        height: 38px;
        width: 38px;

        img {
            width: 16px;

            path {
                fill: #fff;
            }
        }

        .add-icon-svg {
            svg {
                margin-bottom: 5px;
            }
        }

        &:hover,
        &:focus {
            opacity: .75;
        }
    }

    .split-choose-mode {
        width: 45%;
        margin: 0 auto;
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: @primary-color;
    }

    .split-mode {
        width: 45%;
        cursor: pointer;
        background: #fff;
        border: 1px solid rgba(102,110,132,.2);
        border-radius: @border-radius-base;
        padding: 25px 20px 10px;
    }

    .split-mode-icon-on-choose {
        width: 60%;
        display: inline-block;
    }

    .split-mode-description {
        padding-top: 20px;
    }

    .split-mode-title {
        font-weight: bold;
        font-size: 18px;
        margin: 0;
    }

    .split-mode-subtitle {
        margin: 0;
        font-size: 13px;
        line-height: 16px;
    }

    .split-simple-mode-wrapper {
        width: 100%;
        margin: 0 auto;
    }

    .split-simple-mode-icon,
    .split-advanced-mode-icon {
        max-width: 130px;
        display: inline-block;
        width: 100%;
    }

    .split-simple-mode-description,
    .split-advanced-mode-description {
        margin-top: 30px;
        color: @primary-color;
    }

    .split-simple-mode-title,
    .split-advanced-mode-title {
        font-weight: bold;
        font-size: 30px;
    }

    .split-simple-mode-select {
        width: 100%;
        display: flex;
        margin-bottom: 30px;
        padding: 25px 15px;
        background-color: #fff;
        border: 1px solid rgba(102,110,132,.2);
        border-radius: @border-radius-base;
    }

    .split-simple-mode-select-label {
        width: 50%;
        font-size: 13px;
        background-color: @primary-color;
        color: #fff;
        border: 1px solid rgba(102,110,132,.4);
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        padding: 5px;
    }

    .split-simple-mode-input {
        width: 50%;
        outline: 0;
        border: 1px solid rgba(102,110,132,.2);
        border-left: none;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        text-align: center;
    }


    .windows-os-required {
        font-size: 24px;
        font-weight: bold;
    }
    // TEXT TO PDF PAGE START
    .text-toolbar {
        span {
            margin: 0 1px;
            padding: 5px;
            border-radius: @border-radius-base;

            &.selected {
                background-color: @primary-color;
                color: #fff;
            }
        }

        .paging-text-options& {
            border: 2px solid blue
        }
    }


    .font-family-label {
        display: block;
        margin: 0 5px 10px;
    }

    .font-families.ant-dropdown-trigger i.anticon.anticon-down {
        display: inline-flex;
        padding-left: 0;
    }

    .font-size-input {
        width: 69px;
        text-align: center;
        height: 44px !important;

        @media (max-width: 767.98px) {
        }
    }

    .font-size-input.invalid,
    .font-size-input.invalid:hover,
    .font-size-input.invalid:focus,
    .resize-input-w.invalid input,
    .resize-input-w.invalid:hover input,
    .resize-input-w.invalid:focus input,
    .resize-input-h.invalid input,
    .resize-input-h.invalid:hover input,
    .resize-input-h.invalid:focus input {
        border: 1px solid red !important;
    }

    .margins-icon-wrapper {
        position: relative;
        width: 450px;
        margin: 35px auto;

        .ant-input-number > .ant-input-number-handler-wrap {
            display: none;
        }

        .ant-input-number .ant-input-number-input {
            text-align: center;
        }
    }

    .margins-icon {
        max-width: 300px;
        margin: 0 auto;
    }

    .margin-icon-value {
        position: absolute;
        display: inline-block;
        border: 1px solid #dde5ee;
        border-radius: @border-radius-base;
        text-align: center;

        &.ant-input {
            padding: 5px 1px;
            height: auto;
        }

        &.margin-top {
            width: 70px;
            top: -30px;
            left: 190px;
        }

        &.margin-bottom {
            width: 70px;
            bottom: -30px;
            left: 190px;
        }

        &.margin-left {
            left: 45px;
            top: 50%;
            margin-top: -15px;
            width: 70px;
        }

        &.margin-right {
            right: 45px;
            top: 50%;
            margin-top: -15px;
            width: 70px;
        }
    }
    //TEXT TO PDF PAGE END
    // PDF to JPG
    .pdf-to-jpg-quality {
        margin: 0;
        display: inline-block;
        width: 75%
    }
    ///PAGING PDF
    .paging-pdf {
        .position-icon-wrapper {
            position: relative;
            max-width: 230px;
            margin: 0 auto;
        }

        .ant-select {
            &.show {
                display: block !important;
            }

            &.hide {
                display: none !important;
            }
        }

        .validation-error {
            &input {
                border-color: red !important;
            }

            &.ant-select-multiple .ant-select-selector {
                border-color: red !important;
            }
        }

        .position-icon-value {
            position: absolute;
            margin: 0;
            width: 16px;
            height: 16px;

            &.position-top-left {
                top: 0;
                left: 0;
            }

            &.position-top-center {
                top: -10px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            &.position-top-right {
                top: 0;
                right: 0;
            }

            &.position-center-left {
                left: 0;
            }

            &.position-center-right {
                right: 0;
            }

            &.position-bottom-left {
                bottom: 0;
                left: 0;
            }

            &.position-bottom-right {
                bottom: 0;
                right: 0;
            }

            &.position-bottom-center {
                bottom: -10px;
                right: 0;
                left: 0;
                margin: 0 auto;
            }
        }
    }
    ///PAGING PDF
    /*===============================
========== AvePDF Footer ==========
===============================*/
    .footer {
        .copyright {
            color: @primary-color;
        }
    }

    .menu {
        font-size: 14px;
        font-weight: 600;
        color: @primary-color;
    }

    .menu-item {
        margin-right: 48px;
        line-height: 36px;
        vertical-align: bottom;
        cursor: pointer;
    }

    .ave-footer {
        margin-top: 60px;

        .ave-footer-top {
            background-color: #131313;
            padding-top: 30px;
            padding-bottom: 30px;
            color: #fff;

            a {
                color: #fff;
            }

            .footer-top-menu {
                padding-top: 15px;
                padding-bottom: 15px;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    position: relative;

                    li {
                        display: inline-block;
                        margin-right: 30px;
                        cursor: pointer;

                        .pre-menu-icon {
                            width: 20px;
                            margin-right: 15px;
                            display: inline-block;

                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }

                .menu-item-dropdown {
                    position: absolute;
                    left: 0;
                    bottom: 115%;
                    border: 1px solid #474747;
                    background-color: #1f1f1f;
                    box-shadow: 0 20px 35px 0 rgba(0,0,0,.2);
                    padding: 10px;
                    border-radius: @border-radius-base;

                    li {
                        margin: 0;
                        padding: 10px 15px;
                        display: block;

                        &:hover,
                        &:focus {
                            background-color: #2e2e2e;
                        }

                        .dev-icon {
                            margin-right: 10px;
                        }
                    }
                }
            }

            &.new-footer-top {
                background-color: transparent;
                border-top: 1px solid rgba(102,110,132,.2);
                color: @text-color;

                .footer-title {
                    color: @primary-color;
                    font-weight: 600;
                    margin-bottom: 2rem;
                }

                a {
                    color: @text-color;

                    &:hover,
                    &:focus {
                        color: #1d7c76;
                    }
                }

                p {
                    font-size: 14px;
                }

                .footer-list {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    font-size: 14px;
                    margin-bottom: 2rem;

                    li {
                        line-height: 2;
                        padding-bottom: 5px;
                        padding-right: 5px;

                        &.active-lang {
                            a {
                                font-weight: 600;
                                color: #1d7c76;
                            }
                        }
                    }
                }

                .dev-icon {
                    margin-right: 5px;
                }

                .theme-switch {
                    margin: 0 10px 2rem 0;
                    padding: 0;

                    .btn_background {
                        border: 2px solid @primary-color;

                        .btn_face {
                            background: @primary-color;
                        }
                    }
                }

                .powered-by-container {
                    max-width: 240px;
                }
            }
        }

        .ave-footer-bottom {
            background-color: #fff;
            font-size: 13px;
            padding-top: 30px;
            padding-bottom: 30px;

            a {
                text-decoration: underline;
            }

            .footer-bottom-menu {
                padding-top: 15px;
                padding-bottom: 15px;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        display: inline-block;
                        margin-right: 15px;
                    }
                }
            }

            .powered-by-container {
                padding: 15px 0;

                svg {
                    max-width: 210px !important;
                }
            }
        }

        .theme-switch {
            display: inline-block;
            padding: 15px 0;
            margin: 0 0 0 15px;

            .btn_background {
                width: 45px;
                height: 26px;
                border-radius: @border-radius-base * 10;
                background: transparent;
                border: 2px solid #fff;
                cursor: pointer;

                .btn_face {
                    background: #fff;

                    .theme-switch-icon {
                        width: 11px;
                        height: 11px;
                        vertical-align: text-top;
                    }
                }
            }
        }
    }
    /*===============================
========== Logos ==========
===============================*/
    /*============================================
========== 03. AvePDF tooltip start ==========
=============================================*/
    .ave-tooltip-wrapper {
        transition: visibility 0.2s, opacity 0.2s linear;
        -webkit-transition: visibility 0.2s, opacity 0.2s linear;
        min-width: auto;

        @media (max-width: 575.98px) {
            min-width: 180px;
        }
    }

    .element-with-tooltip {


        &:hover .ave-tooltip-wrapper {
            visibility: visible;
            opacity: 1;
            transition: visibility 0.4s, opacity 0.4s linear;
            -webkit-transition: visibility 0.4s, opacity 0.4s linear;
            transition-delay: 0.4s;
        }
    }

    .ave-tooltip {
        display: block;
        padding: 15px;
        font-size: 13px;
        line-height: 16px;
        border-radius: @border-radius-base;
        background: #fff;
        text-align: start;
        position: relative;
        border: 1px solid @border-color-base;

        @media (max-width: 575.98px) {
            max-width: 120px;
        }
    }

    .ave-tooltip-triangle {
        display: block;
        position: absolute;
        border-top: none !important;
        border-left: none !important;
        background: #fff;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border: 1px solid @border-color-base;
        width: 10px;
        height: 10px;
        bottom: -5px;
    }

    .ave-tooltip-layout {
        display: flex;
        flex-direction: column;
        width: 16em;

        @media (max-width: 991.98px) {
            width: 12em;
        }

        @media (max-width: 575.98px) {
            width: 7em;
        }

        span {
            white-space: pre-line;
        }
    }


    .ave-tooltip-title {
        font-weight: bold;
    }
    /*===========================================*/
    /*=========================================
========== 04. Internet Explorer ==========
===========================================*/
    .ie-user-screen {
        width: 100%;
        height: 100%;
        text-align: center;
        display: table;

        .ie-error {
            display: table-cell;
            vertical-align: middle;

            .ie-error p {
                font-size: 26px;
            }
        }
    }
    /*=========================================
========== 04. Buttons ==========
===========================================*/
    button:focus {
        outline: none;
    }
    /* Ant Default Button*/
    .ant-btn {

        svg {
            path {
                fill: @primary-color;
            }
        }
    }
    // Ant Button Group
    .ant-btn-group {
        .ant-btn {
            border-radius: 0;
        }

        .ant-btn:first-child:not(:last-child) {
            border-radius: @border-radius-base 0 0 @border-radius-base;
        }

        .ant-btn:last-child:not(:first-child) {
            border-radius: 0 @border-radius-base @border-radius-base 0;
        }

        .ant-btn-primary:last-child:not(:first-child), .ant-btn-group .ant-btn-primary + .ant-btn-primary {
            border-left-color: @border-color-base;
        }

        .ant-btn:only-child {
            border-radius: @border-radius-base;
        }

        &.ant-btn-pdfa {
            .ant-btn {
                border-radius: @border-radius-base !important;
                width: 100% !important;
                margin: 6px !important;
                padding: 10px 15px !important;
            }
        }
    }

    .ant-btn-group {
        &.ant-btn-group-3 {

            @media (max-width: 991.98px) {
                .ant-btn {
                    width: 100%;
                    margin: 2px;
                    padding: 10px 15px;
                    border-radius: @border-radius-base !important;
                }
            }

            @media (max-width: 767.98px) {
                display: block;

                .ant-btn {
                    width: 100%;
                    margin: 2px;
                }
            }
        }

        &.ant-btn-group-4 {
            @media (max-width: 1199.98px) {
                .ant-btn {
                    width: 45%;
                    margin: 10px;
                    border-radius: @border-radius-base !important;
                    border-color: @border-color-base !important;
                }
            }

            @media (max-width: 991.98px) {
                display: block;

                .ant-btn {
                    display: block;
                    width: 100%;
                    margin: 2px 0;
                }
            }
        }

        &.ant-btn-group-icons {
            display: inline-block;

            @media (max-width: 767.98px) {
                .ant-btn {
                    margin: 4px 2px;
                    border-radius: @border-radius-base !important;
                    border-color: @primary-color !important;
                }
            }

            @media (max-width: 575.98px) {
                .ant-btn {
                    margin: 6px 8px;
                }
            }
        }

        &.ant-btn-group-white {
        }

        @media (max-width: 575.98px) {
            .ant-btn {
                white-space: normal;
            }
        }
    }
    // Buttons
    .btn {
        color: @primary-color;
        outline: none;

        &:focus {
            box-shadow: @box-shadow-base;
        }

        .btn-white {
            background: #fff;
            border-color: @primary-color;
        }
    }
    /* White Button */
    .white-action-button {
        border-width: 1px;
        border-style: solid;
        border-color: @primary-color;

        &:hover,
        &:focus {
        }

        .material-icons {
            padding-left: 15px;
        }
    }
    /* Upload & Download Buttons Group */
    .download-file-methods-wrapper,
    .upload-file-methods {
        position: relative;
        list-style-type: none;
        margin: 0 auto;

        .rename-download-file {
            display: block;
            width: 100%;
            margin-bottom: 30px;

            .ant-input-group-wrapper {
                width: 100%;

                .ant-input-affix-wrapper {
                    padding-right: 16px;
                    border: none;
                    border-radius: @border-radius-base 0 0 @border-radius-base;
                    border-color: @border-color-base;

                    &:hover {
                        border-color: rgba(102,110,132,.4);
                    }
                }

                .ant-input-group-addon {
                    border: none;
                    border-radius: @border-radius-base @border-radius-base 0 0;
                    background-color: transparent;

                    &:last-child {
                        padding: 0;
                    }
                }

                .ant-input {
                    height: 44px;
                    font-size: 1em;
                }
            }

            .ant-btn {
                min-width: 46px;
                border-radius: 0;
                border-width: 0;

                &:last-child {
                    border-radius: 0 @border-radius-base @border-radius-base 0;
                }
            }

            .rename-on {
                .rename-cancel-btn {
                    background-color: #edf2f6;
                }
            }

            .rename-off {

                .ant-btn {
                    background: none;
                }

                span {
                    line-height: 1;
                    padding: 19px 11px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 600;
                }
            }

            .output-file-name {
                @media (max-width: 767.98px) {
                    white-space: normal !important;
                }
            }
        }
    }

    .rename-btn {
        border: 1px solid transparent;
        border-radius: @border-radius-base !important;
        background-color: transparent;
    }

    .rename-download-file {

        .output-file-name {
            text-decoration: underline;
        }
    }

    .upload-file-methods {
        margin: 15px auto 0;
    }

    .download-file-methods-wrapper {
        display: inline-block;
    }

    .download-file-methods {
        margin-top: 30px;
        display: block;
    }

    .upload-file-methods,
    .download-file-methods {


        button,
        button:hover,
        button:focus {

            &.upload-file-download {
                top: 3px;

                @media (max-width: 992px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                .cloud-upload-icon {
                    width: 32px;
                    height: 32px;

                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }


                p {
                    display: inline-block;
                    margin: 0;
                    font-size: 14px;
                    line-height: 1;
                    vertical-align: bottom;

                    .file-name {
                        display: block;
                        width: 160px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 2px;
                    }

                    .file-size {
                    }
                }


                .download-icon-svg {
                    width: 32px !important;
                }
            }
        }
    }



    .download-file-methods li:first-child button .cloud-upload-icon,
    .upload-file-methods li:first-child button .cloud-upload-icon {
        margin-right: 10px;
    }


    .download-file-methods li:last-child button {
        border-top-left-radius: 0;
        border-bottom-right-radius: @border-radius-base;
    }

    .upload-file-methods li:last-child button {
        border-top-right-radius: @border-radius-base;
        border-bottom-right-radius: @border-radius-base;
    }

    .upload-file-methods-small {
        display: none !important;

        .upload-more {
            margin: 0;
        }

        .upload-more-methods {
            width: 100%;
        }

        .upload-more-methods li {

            .ant-btn {
                border: 1px solid;
                border-top: none;
                padding: 10px 15px;
                margin-bottom: 0;


                &.ant-btn-primary {
                    color: @primary-color;
                    background-color: #fff;
                    border-color: @primary-color;
                }
            }

            &:first-child .ant-btn {
                border-top: 1px solid;
            }
        }
    }

    .upload-url-input {

        @media (max-width: 767.98px) {
            max-width: 100%;
            min-width: 100%;
        }

        .ant-input-group-addon {
            border: none;
            border-radius: @border-radius-base;
            background: none;

            @media (max-width: 767.98px) {
                border-radius: 0;
                display: block;
                width: 100%;
            }

            &:first-child {
                background: #fff;
                border: 1px solid @border-color-base;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                @media (max-width: 767.98px) {
                    border-radius: 0 !important;
                }
            }
        }

        .ant-input {
            font-size: 1em;
            height: 57px;

            @media (max-width: 767.98px) {
                float: none;
                display: block;
                width: 100%;
            }

            &:hover {
                border-color: @border-color-base;
            }

            &::placeholder {
                font-size: 12px;
            }
        }

        .url-input-icon {
            padding: 0 10px;

            @media (max-width: 767.98px) {
                padding: 10px;
            }
        }

        .ant-input-group-addon {
            padding: 0;
        }

        .ant-btn {
            border-radius: 0;
            padding: 17px 15px;
            border-color: @border-color-base !important;

            @media (max-width: 767.98px) {
                display: inline-block;
                width: 50%;
                border-radius: 0 !important;
            }

            &:last-child {
                border-top-right-radius: @border-radius-base;
                border-bottom-right-radius: @border-radius-base;
            }

            &.url-cancel-btn {
                background-color: #edf2f6;
                border-color: @border-color-base;
            }
        }

        &.uri-input-invalid {

            .ant-input-group-addon:first-child {
                border-color: @error-color;
            }

            .url-input-icon {
                svg {
                    path {
                        fill: @error-color;
                    }
                }
            }

            .ant-input {
                color: @error-color;
                border-color: @error-color;
            }
        }
    }


    .file-upload-option-wrapper {

        .choose-file-btn {
            color: #1d7c76;

            svg {
                path {
                    fill: #1d7c76;
                }
            }
        }

        .file-upload-option {
            .ant-btn {

                &:hover,
                &:focus {
                    background-color: #1d7c76;
                    color: #fff;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }


    .download-file-methods {

        .download-file-btn {
            color: #1d7c76;

            svg {
                path {
                    fill: #1d7c76;
                }
            }
        }

        .file-download-option {
            .ant-btn {

                &:hover,
                &:focus {
                    background-color: #1d7c76;
                    color: #fff;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }
    //Tabs
    .ant-tabs {
        color: @text-color;

        &.avepdf-account-tabs {

            .ant-tabs-tab {
                &.ant-tabs-tab-active {
                    background: rgba(102,110,132,.1);

                    .ant-tabs-tab-btn {
                        font-size: 1.1em;
                        color: @text-color;
                    }
                }

                .ant-tabs-tab-btn {
                    font-size: 1.1em;
                }
            }

            .plan-features {

                li {
                    padding: 4px 0;
                }
            }

            .ant-tabs-tab {

                &:hover {

                    .ant-tabs-tab-btn {
                        color: #1D7C76 !important;
                    }
                }
            }
        }
    }


    .ant-tabs-ink-bar {
        background: @text-color;
    }

    .ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder {
        border-left: 1px solid rgba(102,110,132,.4);
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
        transition: none;
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        transition: none;
    }

    .avepdf-upload-tabs {

        .ant-tabs-nav {
            margin: 0;


            .ant-tabs-nav-list {
                white-space: initial;

                .ant-tabs-tab {
                    border-color: #dee2e6;
                    border-width: 1px;
                    border-style: solid;
                    border-top-left-radius: @border-radius-base;
                    border-top-right-radius: @border-radius-base;

                    &:first-child {
                        margin-left: 0 !important;
                    }

                    .ant-tabs-tab-btn {
                    }

                    &.ant-tabs-tab-active {
                        border-bottom-color: #fff;

                        .ant-tabs-tab-btn {
                            color: @primary-color;
                        }
                    }
                }

                @media (max-width: 767.98px) {
                    display: block;
                    width: 100%;
                    margin-bottom: 15px;

                    .ant-tabs-tab {
                        display: block;
                        width: 100%;
                        padding: 0.75rem 1.5rem;
                        margin: 0 !important;
                        border-radius: 0;

                        &:first-child {
                            border-radius: @border-radius-base @border-radius-base 0 0;
                            margin-top: 0;
                        }

                        &:nth-last-child(2) {
                            border-radius: 0 0 @border-radius-base @border-radius-base;
                        }

                        &.ant-tabs-tab-active {

                            .ant-tabs-tab-btn {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .ant-tabs-content-holder {
            border-bottom-left-radius: @border-radius-base;
            border-bottom-right-radius: @border-radius-base;

            @media (max-width: 767.98px) {
                border-width: 1px;
                border-top-left-radius: @border-radius-base;
                border-top-right-radius: @border-radius-base;
                border-bottom-left-radius: @border-radius-base;
                border-bottom-right-radius: @border-radius-base;
            }

            .upload-url-input {

                @media (max-width: 767.98px) {
                    border-radius: 0;
                    display: block;
                    width: 100%;
                }

                .ant-input-group-addon {

                    &:first-child {
                        @media (max-width: 767.98px) {
                            border-radius: @border-radius-base @border-radius-base 0 0;
                        }
                    }

                    .ant-btn {
                        @media (max-width: 767.98px) {
                            display: inline-block;
                            width: 100%;
                            border-radius: 0 0 @border-radius-base @border-radius-base !important;
                        }
                    }
                }
            }

            .upload-more-button-wrapper {
                .upload-more-methods {
                    position: relative;
                    width: 100% !important;
                    right: 0;
                    left: 0;

                    .ant-btn {

                        img {
                            position: absolute;
                            top: 50%;
                            margin-top: -10px;
                        }

                        &.action-button {
                            min-width: auto;
                        }
                    }
                }

                .ant-btn {
                    &.upload-more {
                        white-space: normal;

                        span {
                            margin: 0 5px;
                        }
                    }
                }

                @media (max-width: 767.98px) {
                    padding: 0;
                    margin: 0;
                }
            }
        }

        .ant-tabs-nav-operations {
            display: none !important;
        }

        @media (max-width: 991.98px) {
            margin: 30px 0;
        }
    }
    /*----------------------
    SDK Merchandizing
    -----------------------*/
    .tool-powered-by {
        padding: 10px 0;
        font-size: 14px;
    }

    .tool-powered-centered {
        .tool-powered-by {
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            padding: 10px;
            text-align: center;
            border: 2px solid rgba(102,110,132,.4);
            border-top: none;
        }

        #dragAndDropArea {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.tool-powered-strong {

            .tool-powered-by {
                background-color: #1d7c76;
                border-color: #1d7c76;
                color: #C3D5D6;
                margin-top: -2px;

                a {
                    color: #fff;
                }
            }
        }
    }
    /*===============================
========== Forms & Inputs ==============
===============================*/
    .ant-input {
        background-image: none;
        border-radius: @border-radius-base;
        transition: none;
    }

    .prepend-append-input {
        border-radius: 0 !important;
    }

    .ant-input-group-wrapper {
        .ant-input-wrapper {
            .ant-input-group-addon {
                border-radius: @border-radius-base;

                &:first-child {
                    border-radius: @border-radius-base 0 0 @border-radius-base;
                }

                &:last-child {
                    border-radius: 0 @border-radius-base @border-radius-base 0;
                }
            }
        }
    }

    .ant-input-number-handler {
        border-left: 1px solid transparent;
    }

    .ant-input-number {
        height: 45px;
        border-radius: @border-radius-base;

        span {
            padding: 0;
            display: block;
            margin: 0;
        }



        .ant-input-number-handler-up-inner {
            top: 25%;
        }

        .ant-input-number-handler-up:hover,
        .ant-input-number-handler-down:hover {
            svg path {
                fill: @primary-color;
            }
        }

        &:hover {
            border-color: rgba(102,110,132,.4);
        }

        &:focus,
        &.ant-input-number-focused {
            outline: 0;
            box-shadow: 0 0 0 2px rgba(@primary-color, .2);
            border-color: rgba(102,110,132,.4);
        }

        .ant-input-number-handler-wrap {
            border-radius: 0 @border-radius-base @border-radius-base 0;
        }

        .ant-input-number-input {
            height: 45px;
        }

        .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
        .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
            color: @primary-color;
        }
    }

    .ant-input-number-rtl .ant-input-number-handler {
        border-right: 1px solid transparent;
        border-left: 0;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: @primary-color;
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: @primary-color;
            border-color: @text-color;
        }

        &:after {
            border-color: @text-color;
        }
    }

    :hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: @primary-color;
    }

    .ant-radio-checked {
        .ant-radio-inner {
            border-color: @primary-color;

            &:after {
                background-color: @primary-color;
            }
        }
    }

    .ant-switch {
        background-color: @disabled-color;
    }
    // Ant Radio Group
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: @border-color-base;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: @border-color-base;
    }

    .ant-radio-button-wrapper {
        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none;

        &:first-child {
            border-radius: @border-radius-base 0 0 @border-radius-base;
        }

        &:last-child {
            border-radius: 0 @border-radius-base @border-radius-base 0;
        }

        @media (max-width: 768px) {
            display: inline-block;
            border-radius: @border-radius-base !important;
            margin: 3px 3px 6px;
        }
    }
    /*========== Forms ==========*/
    input:-webkit-autofill {
        box-shadow: inset 0 0 0px 9999px white !important;
    }

    input,
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="search"],
    input[type="password"] {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
        outline: none;
        background-clip: padding-box;
    }

    .form-control {
        color: @primary-color;
        border: 1px solid @border-color-base;
        -webkit-border-radius: @border-radius-base;
        border-radius: @border-radius-base;

        &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
            border-color: rgba(102,110,132,.4);
        }
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: lighten(@primary-color, 30%);
        opacity: 1;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: lighten(@primary-color, 30%);
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: lighten(@primary-color, 30%);
    }


    .form-group {
        margin-bottom: 30px;
    }

    .input-lg {
        height: 50px;
        font-size: 1em;
    }

    .input-sm {
        height: 30px;
    }

    .input-group-btn {
        font-size: 14px;
    }

    .input-group > .form-control,
    .input-group > .input-group-addon,
    .input-group > .input-group-btn > .btn {
        padding: 0.375rem 0.9375rem;
        -webkit-border-radius: @border-radius-base;
        //border-radius: @border-radius-base;
        border-radius: 0 @border-radius-base @border-radius-base 0;
    }

    .input-group-lg > .form-control,
    .input-group-lg > .input-group-addon,
    .input-group-lg > .input-group-btn > .btn {
        height: 50px !important;
        font-size: 1em;
    }

    .input-group-sm > .form-control,
    .input-group-sm > .input-group-addon,
    .input-group-sm > .input-group-btn > .btn {
        height: 30px;
        padding: 5px 25px;
    }

    .form-group-lg .form-control {
        height: 60px;
    }

    .input-group {
        .form-control {
            z-index: inherit;
        }
    }

    .input-group:not(.has-validation) > .form-control:not(:last-child) {
        border-top-right-radius: @border-radius-base;
        border-bottom-right-radius: @border-radius-base;
    }

    .remove-list-elem {
        .input-group {

            .input-group-append {
                @media (max-width: 767.98px) {
                    display: block;
                    margin-top: 10px;
                    width: 100%;

                    .input-group-text {
                        border-radius: @border-radius-base;
                    }
                }
            }
        }
    }

    .input-group-addon {
        background-color: #d4eafa;
        border-color: #d4eafa;
        color: #42a5f5;
    }

    .input-group-addon.input-success {
        background-color: #f0fbf1;
        border-color: #f0fbf1;
        color: #81c784;
    }

    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control label {
        background-color: #f7fbff;
    }

    .border-form {
        .form-control {
            background: transparent;
        }

        .dark-form {
            .form-control {
                background: transparent;
                border: 1px solid #354063;

                &::-moz-placeholder {
                    color: #354063;
                }
            }
        }
    }

    label {
        font-weight: 300;
        //margin-bottom: 5px;
    }

    @media (max-width: 992px) {
        .form-inline .form-group {
            margin-bottom: 15px;
        }
    }

    .input-group-text {
        border-color: @border-color-base;
        background-color: lighten(@primary-color, 85%);
        font-size: 0.875em;
        border-radius: @border-radius-base;
    }

    .input-group-icon {
        position: relative;

        .form-control {
            padding-left: 44px;
        }

        .input-icon {
            position: absolute;
            left: 12px;
            width: 20px;
            top: 50%;
            z-index: 100;
            height: 20px;
            margin-top: -12px;
        }
    }

    .font-styles {
        display: inline-block;
        background-color: #fff;
        margin: 0 0 0 5px;
        border: 1px solid @border-color-base;
        padding: 8px;
        border-radius: @border-radius-base;

        @media (max-width: 767.98px) {
            width: auto;
        }
    }

    .text-alignment {
        display: inline-block;
        background-color: #fff;
        margin: 0 5px;
        border: 1px solid @border-color-base;
        padding: 8px;
        border-radius: @border-radius-base;

        @media (max-width: 767.98px) {
            width: auto;
        }
    }
    /*===============================
========== Select & Dropdown ==============
===============================*/
    .ant-dropdown-menu {
        border-radius: @border-radius-base;
        padding: 0 !important;

        li:first-child {
            border-radius: @border-radius-base @border-radius-base 0 0;
        }

        li:last-child {
            border-radius: 0 0 @border-radius-base @border-radius-base;
        }

        &.ant-dropdown-menu-light {
            padding: 0;
            border: 1px solid @border-color-base;

            .ant-dropdown-menu-item-selected,
            .ant-dropdown-menu-submenu-title-selected,
            .ant-dropdown-menu-item-selected > a,
            .ant-dropdown-menu-submenu-title-selected > a {
                color: @primary-color;
                background-color: @border-color-base;
            }
        }
    }

    .language-overlay {

        .ant-dropdown-menu {
            margin-top: 0;
            padding: 0;
        }
    }

    .ant-select {
        &.small-select {
            min-width: 160px;
        }

        &.validation-error {
            .ant-select-selector {
                border-color: #ff4d4f !important;
            }
        }
    }

    .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
        background-color: lighten(@primary-color, 75%);
    }

    .ant-select-selection {
        background-color: #fff;
        border-color: @border-color-base;
    }

    .ant-select-dropdown {
        border-color: @border-color-base;
        border-radius: @border-radius-base;
    }

    .ant-select-multiple .ant-select-selector {
        padding: 10px;
    }

    .ant-select-multiple .ant-select-selection-item-remove > .anticon {
        vertical-align: middle;
    }

    .ant-select-arrow {
        line-height: 52px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 52px;
        border-radius: @border-radius-base;
    }

    .ant-select-single .ant-select-selector {

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
            line-height: 52px;
        }
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: @primary-color;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: @primary-color;
        background-color: lighten(@primary-color, 80%);
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: lighten(@primary-color, 80%);
    }

    .ant-select-selection__rendered {
        line-height: 52px;
    }

    .ant-select-dropdown {
        background-color: lighten(@primary-color, 85%);
    }

    .ant-select-dropdown-menu {
        max-height: 300px;
    }

    .ant-select-dropdown-menu-item:hover {
        background-color: lighten(@primary-color, 80%);
    }

    .ant-select-dropdown-menu-item-active {
        background-color: lighten(@primary-color, 80%);
    }

    .ant-dropdown-trigger {
        background-color: lighten(@primary-color, 90%);
        border: 1px solid @border-color-base;
        display: inline-block;
        cursor: default;

        &.selected-language {
            background-color: transparent;
            border-color: transparent;
        }

        &.font-families {
            margin: 0 5px;
            padding: 9px;
            border-radius: @border-radius-base;
        }

        .anticon {
            &.anticon-down {
                display: initial;
                line-height: 16px;
            }

            svg {
                path {
                    fill: @primary-color
                }
            }
        }

        &.dropdown-button-trigger {
            &:hover,
            &:focus {
                background-color: @primary-color !important;
                color: #fff;
                border: 1px solid @border-color-base;

                .anticon {
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }

    .paging-text-options {
        .font-families {
            margin-left: 0;
        }
    }
    /*===============================
========== Filters ==============
===============================*/
    .btn-filters {

        .ant-btn {
            border: 1px solid @border-color-base;
            background-color: #fff;

            @media (max-width: 992px) {
                width: 48%;
                margin: 0 1% 1% !important;
                border-radius: @border-radius-base !important;
            }

            &:first-child {
                @media (max-width: 992px) {
                    width: 100%;
                }
            }

            &.custom-active-on {
                color: @primary-color;
                background-color: rgba(234,234,250,.2);

                &:hover, &:focus {
                    color: @primary-color;
                }
            }

            &.custom-active-off {
                color: @primary-color;
                border: 1px solid rgba(102,110,132,.2);

                &:hover {
                }
            }
        }
    }

    .btn-other-tools {

        .ant-btn {
            border: 1px solid @border-color-base;

            span {
                transition: none;
            }

            svg {
                path {
                }
            }

            &:hover,
            &:focus {
                color: @primary-color;
            }

            @media (max-width: 992px) {
                width: 100%;
            }
        }

        @media (max-width: 992px) {
            width: 100%;
        }
    }


    .other-tools-dropdown {
        padding: 0;
        box-shadow: none;
        border: 1px solid @border-color-base;
        border-radius: @border-radius-base;

        li {
            font-size: 0.925em;
            border-top: 1px solid @border-color-base;

            &:first-child {
                border: none;
            }

            a {
                padding: 10px;
                display: block;

                .async-svg-container {
                    display: inline-block;
                    width: 18px;
                    margin-right: 10px;

                    svg {

                        path {
                            fill: @primary-color !important;
                        }
                    }
                }
            }

            &:hover {
                background-color: #f7f8fb;
            }
        }
    }
    /*===============================
========== Search - tools ==============
===============================*/
    .search-wrapper {
        position: relative;
        display: inline-block;

        .search-tools {
            color: @text-color;
            background-color: #ffff;
            border-color: rgba(102,110,132,.2);
            padding: 9px 10px;
            line-height: inherit !important;
            font-size: 14px;
        }
    }

    .search-icon {

        svg {
            path {
                fill: @primary-color;
            }
        }
    }

    .search-tools {
        height: auto !important;
        padding: 10px;

        &.form-control {
            color: @primary-color;
            -webkit-border-radius: @border-radius-base;
            border-radius: @border-radius-base;

            &:focus {
                border-color: rgba(102,110,132,.4);
            }
        }
    }

    @media (max-width: 991px) {
        /* filters buttons */

        .btn-filters .btn:first-child {
            width: 100%;
        }

        .btn-filters .btn {
            width: 46%;
            margin: 0 1% 1% !important;
            border-radius: @border-radius-base !important;
        }
        /* search tools */
        .search-wrapper {
            width: 98%;
            margin: 0 1% 0;
        }

        .search-tools.form-control {
            margin: 0 auto;
        }
    }
    /*===============================
========== Tool Item ==============
===============================*/
    .tool-item-wrapper {

        @media (max-width: 992px) {
            padding-bottom: 15px;
        }
        /*====== New widget layout ======*/
        .tool-item-block {
            border: 1px solid rgba(102, 110, 132, 0.2);
            background-color: rgba(234,234,250,.08);

            .tool-item-block-title {
                color: @primary-color;
            }

            .tool-item-block-description {
                color: lighten(@primary-color, 25%);
            }

            .tool-item-block-arrow {
                color: @primary-color;

                svg {
                    path {
                        fill: @primary-color;
                    }
                }
            }
        }
    }

    .tool-tooltip-wrapper {
        width: 100%;
        white-space: normal;

        .ave-tooltip {
            max-width: 190px;
            margin: 0 auto;
        }

        .ave-tooltip-triangle {
            height: 15px;
            width: 15px;
            bottom: -9px;
            left: 45%;
        }
    }
    /*====== Tool Page Content ======*/

    .tool-header-outer-container .header-icon {
        margin-right: 15px;
    }

    .upload-icon {

        svg {
            object-fit: contain;
            display: block;
        }
    }

    .loading-icon {
        object-fit: contain;
        display: block;
        fill: @primary-color;
    }

    .upload-more-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .document-icon {
        width: 64px;
        height: 64px;
        display: block;
        margin: 16px auto;

        svg {
            path {
                fill: @text-color;
            }
        }
    }

    .upload-container,
    .upload-in-progress {
        margin: 0 25px;

        @media (max-width: 767.98px) {
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    .shape-selection-toolbar-popover .cloud-upload-icon {
        width: 22px;
    }

    .download-icon {
        width: 60px;
    }

    .download-icon-wrapper {
        display: inline-block;

        @media (max-width: 575px) {
            display: block;
            text-align: center;
        }
    }

    .savetogoogledrive-button {
        position: absolute;
        z-index: 999;
        top: 20px;
        opacity: 0.0000001;
    }

    .upload-more-with-action {
        padding: 24px 0;

        .ant-input-group-wrapper {
            vertical-align: middle;
            margin-bottom: 0;
            margin-top: -4px;
        }

        .upload-url-input {
        }

        .upload-more-methods {
            @media (max-width: 575.98px) {
                right: 10px;
                left: 10px;
            }
        }
    }

    .upload-more-button-wrapper .upload-more {
        margin: 0;
        padding: 15px 15px;
    }

    .upload-more-methods ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .download-options,
    .upload-more-methods {
        position: absolute;
        z-index: 99;
        border-radius: @border-radius-base;
        width: auto;
        margin-top: 5px;
    }

    .download-options ul,
    .upload-more-methods ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .download-options ul li,
    upload-more-methods ul li {
        position: relative;
    }

    .download-options ul li .savetogoogledrive-button,
    .download-options ul li .savetogoogledrive-button > iframe,
    .download-options ul li .savetogoogledrive-button > iframe .save-to-drive-button {
        height: 100% !important;
        left: 0;
        position: absolute;
        top: 0;
        width: 100% !important;
    }

    .download-options ul li button:hover,
    .download-options ul li button:focus {
        background-color: #f5f9fc;
    }

    .download-options {
        ul {
            li {
                button {
                    &.download-action-button {
                        width: 100%;
                        text-align: start;
                        height: 40px;
                        padding: 15px;
                        border-radius: 0;
                        margin-top: -2px;
                    }
                }

                &:first-child {
                    button {
                        &.download-action-button {
                            border-radius: @border-radius-base @border-radius-base 0 0;
                        }
                    }
                }

                &:last-child {

                    button {
                        &.download-action-button {
                            border-radius: 0 0 @border-radius-base @border-radius-base;
                        }
                    }
                }
            }
        }
    }

    .download-options ul li .cloud-upload-icon {
        margin: 0 10px;
    }

    .download-options ul li .cloud-upload-icon svg {
        width: 20px;
    }

    .download-options ul li button.download-action-button svg path {
        fill: @primary-color;
    }

    .upload-more-methods {

        ul {
            li {
                .ant-btn {
                    margin-bottom: -1px;
                    text-align: start;
                    border-color: @primary-color;

                    &.action-button {
                        min-width: 200px;
                    }

                    &.ant-btn-primary {
                        color: @primary-color;
                        background-color: #fff;
                        border-color: @primary-color;

                        &:hover,
                        &:focus {
                            background-color: @primary-color;
                            border-color: @primary-color;
                            color: #fff;

                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }

                        @media (max-width: 526.98px) {
                            white-space: initial;
                        }
                    }

                    &.action-button {
                        width: 100%;
                        text-align: start;
                        height: 40px;
                        padding: 15px;
                        border-radius: 0;
                    }

                    &:hover,
                    &:focus {
                        background-color: @primary-color;
                        border-color: @primary-color;
                        color: #fff;

                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }

                    svg {
                        width: 20px;
                    }

                    .upload-more-label {
                        margin-left: 10px;
                        font-size: 14px;

                        @media (max-width: 767.98px) {
                            white-space: break-spaces;
                        }
                    }
                }

                &:first-child {
                    .ant-btn {
                        border-radius: @border-radius-base @border-radius-base 0 0;
                        border-color: @primary-color;
                    }
                }

                &:last-child {

                    .ant-btn {
                        &.action-button {
                            border-radius: 0 0 @border-radius-base @border-radius-base;
                        }
                    }
                }
            }
        }

        @media (max-width: 575.98px) {
            position: absolute;
            z-index: 99;
            border-radius: @border-radius-base;
            width: auto !important;
            right: 0;
            left: 0;
            margin: 5px auto 0;
        }
    }

    .tool-action-title {
        color: @primary-color;
    }

    .tool-action-sub-title {

        @media (max-width: 767.98px) {
            margin: 15px auto 15px;
        }
    }

    .tools-upload {
        .powered-by-link {
            @media (max-width: 575.98px) {
                text-align: center;
            }
        }
    }

    .tool-faltten-option-title {
        font-size: 1.2em;
        font-weight: bold;
        margin: 0;
        padding-bottom: 24px;
    }

    .incorrect-password {
        color: @error-color;
    }

    .action-button,
    .action-button:hover,
    .action-button:focus,
    .close-action-button:hover,
    .close-action-button:focus {
        color: @primary-color;
    }

    .action-button,
    .close-action-button {
        border-radius: @border-radius-base;
        color: @primary-color;
    }

    .close-action-button {
        border: none;
    }

    .action-button[disabled],
    .action-button[disabled]:hover {
        color: @disabled-color;
    }

    .close-action-button:hover,
    .close-action-button:focus {
    }
    /*====== Custom Notifications ======*/
    .ave-notifications {

        .notif-language-change,
        .notif-consent {
            border: 1px solid @border-color-base;
            border-radius: @border-radius-base;

            a {

                &:hover,
                :focus {
                    color: lighten(@primary-color, 65%);
                }
            }
        }


        .language-changer-loader {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 12px;
        }

        .btn {
            background-color: @primary-color;
            border-color: @primary-color;
            color: #fff;

            svg {
                path {
                    fill: #fff;
                }
            }

            &:hover,
            :focus {
                background-color: lighten(@primary-color, 15%);
                border-color: lighten(@primary-color, 15%);
                color: #fff;

                svg {
                    path {
                        fill: #fff;
                    }
                }
            }

            &.btn-cancel {
                background-color: @default-color;
                border-color: @default-color;

                svg {
                    path {
                        fill: @primary-color;
                    }
                }
            }

            &.btn-confirm {
                background-color: #b7cc27;
                border-color: #b7cc27;

                svg {
                    path {
                        fill: #fff;
                    }
                }

                &:hover,
                :focus {
                    background-color: #a9ca2f;
                    border-color: #a9ca2f;
                }
            }
        }
    }
    /*====== Notifications ======*/
    .ant-notification-notice {
        padding: 10px 20px;
        border: 1px solid @border-color-base;
        line-height: 1.5715;


        @media (max-width: 767.98px) {
            width: 95%;
        }

        a {
            text-decoration: underline;

            &:hover,
            :focus {
                color: lighten(@primary-color, 65%);
            }
        }

        .ant-notification-notice-message {
            font-size: .925em;
            line-height: inherit;
        }

        .ant-notification-notice-btn {
            margin-top: 15px;

            .ant-btn {
                padding: 8px 15px;
                background-color: @primary-color;
                border-color: @primary-color;
                color: #fff;

                svg {
                    path {
                        fill: #fff;
                    }
                }

                &:hover,
                :focus {
                    background-color: lighten(@primary-color, 15%);
                    border-color: lighten(@primary-color, 15%);
                    color: #fff;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                &.btn-cancel {
                    background-color: @default-color;
                    border-color: @default-color;
                    margin-right: 5px;

                    svg {
                        path {
                            fill: @primary-color;
                        }
                    }
                }

                &.btn-confirm {
                    background-color: #b7cc27;
                    border-color: #b7cc27;
                    margin-right: 8px;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }

                    &:hover,
                    :focus {
                        background-color: #a9ca2f;
                        border-color: #a9ca2f;
                    }
                }
            }
        }
        /*====== notification consent ======*/
        &.notif-cconsent {

            .ant-notification-notice-message {
                padding: 0;
                margin: 0;
            }

            .ant-notification-notice-btn {

                .ant-btn {
                    font-weight: 600;

                    &:hover,
                    :focus {
                    }
                }
            }
        }
        /*====== notification language changer ======*/
        &.notif-language-change {
            padding-top: 15px;
            padding-bottom: 15px;

            .notif-country-flag {
                width: 18px !important;
                height: auto !important;
                margin-right: 5px;
                vertical-align: initial !important;
                margin-bottom: -2px;
            }

            .language-changer-loader {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 12px;
            }

            .ant-notification-notice-btn {
                margin-top: 10px;
            }
        }
    }

    .ant-notification-notice-close {
        top: 8px;
    }
    /*===============================
========== Cards ==============
===============================*/
    .card {
        border: 1px solid rgba(160, 181, 206, 0.44);
    }

    .card-header {
        border-color: rgba(160, 181, 206, 0.44);
    }
    /*==================================================
========== 15. AvePDF Custom CSS ==========
====================================================*/

    .section-separator {
        svg {
            path {
                fill: rgba(244, 247, 254, 1);
            }
        }
    }
    /*====== SDK OFFER ======*/


    .sdk-offer-wrapper {
        background-color: rgba(244, 247, 254, 1);

        .sdk-offer-container {
            overflow-x: hidden;
            width: 100%;
            position: relative;
        }
    }

    .sdk-offer {

        .features-list {

            li {

                &:first-child {
                    &:before {
                        display: none;
                    }
                }

                &:before {
                    content: '\2022';
                    margin: 0 5px;
                    line-height: unset;
                    position: relative;
                    display: inline-block;
                    font-size: 32px;
                    vertical-align: bottom;
                }
            }
        }
    }

    .sdk-offer-card {
        border-radius: @border-radius-base;
        border: 1px solid rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 35px 0px rgba(190, 195, 215, 0.6);
        transition: 0.3s padding ease;

        .card-header {
            padding: 0;
            margin-bottom: 5px;
            background-color: transparent;
            border-bottom: none;
            transition: 0.3s ease !important;
        }

        .card-footer {
            background-color: transparent;
            transform: translateY(100%);
            transition: 0.3s ease !important;
        }

        &:hover,
        &:focus {
            border: 1px solid rgba(255, 255, 255, 0.7);
            background-color: rgba(255, 255, 255, 0.7);
            padding: 20px 15px 76px;

            .card-footer {
                transform: translateY(0%);
                position: absolute;
                bottom: 17px;
            }
        }

        .sdk-offer-card-icon {
            display: inline-block;
            width: 64px;
            margin: 0 auto;
            margin-bottom: 15px;
        }

        .sdk-offer-card-title {
            font-size: 1.1em;
            font-weight: 500;
        }

        .sdk-offer-card-logo {

            &.gdp-logo {
                svg {
                    path.path-gd-color {
                        fill: @primary-color;
                    }
                }
            }

            &.pp-logo {
                svg {
                    path {
                        fill: @primary-color;
                    }
                }
            }
        }

        .sdk-offer-card-link {
            color: @primary-color;

            &:hover,
            &:focus {
                opacity: 0.75;
            }

            .external-icon {
                width: 16px;
                margin-left: 10px;
                display: inline-block;
                vertical-align: text-bottom;
            }

            &.purple {
                color: #9b76d8;

                svg {
                    path {
                        fill: #9b76d8;
                    }
                }
            }

            &.blue {
                color: #0382d4;

                svg {
                    path {
                        fill: #0382d4;
                    }
                }
            }
        }
    }
    /*====== NEW SDK OFFER ======*/
    .new-sdk-offer-wrapper {
        margin: 60px 0;

        .sdk-offer-container {
            overflow-x: hidden;
            width: 100%;
            position: relative;

            .new-sdk-offer-card {
                margin: 30px 0;
                background: #FFFFFF;
                box-shadow: 0px 40px 70px -20px rgba(0, 0, 0, 0.1);
                border-radius: @border-radius-base * 5;
                border: none;
                z-index: 1;

                &:hover {
                    box-shadow: 0px 40px 70px -20px rgba(0, 0, 0, 0.2);
                }

                .card-body {
                    padding: 3rem 2rem;

                    .sdk-offer-card-description {
                        margin: 2rem 0;
                    }
                }

                .ant-btn {
                    padding: 10px !important;
                    color: #fff !important;
                    background-color: #1D7C76;
                    border: none;
                    border-radius: @border-radius-base;

                    &:hover,
                    &:focus {
                        background-color: #75C7B8;
                    }
                }
            }
        }

        .background-shape {
            position: absolute;
            border-radius: @border-radius-base * 5;
            opacity: 1;

            &.background-shape1 {
                background-color: #f7d370;
                width: 48px;
                height: 48px;
                top: -8px;
                right: -8px;
                left: auto;
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                border-radius: @border-radius-base * 3;
            }

            &.background-shape2 {
                background-color: #7340f3;
                width: 24px;
                height: 24px;
                top: -40px;
                right: -40px;
                left: auto;
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                border-radius: @border-radius-base * 2;
            }

            &.background-shape3 {
                background-color: #d63125;
                width: 32px;
                height: 32px;
                bottom: 20%;
                left: -40px;
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                border-radius: @border-radius-base * 2;
                top: auto;
            }

            &.background-shape4 {
                background-color: rgba(117, 199, 184, 0.2);
                width: 32px;
                height: 32px;
                bottom: -10%;
                left: 20px;
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                border-radius: @border-radius-base * 2;
                top: auto;
            }

            &.background-shape5 {
                background-color: #ee9777;
                width: 16px;
                height: 16px;
                bottom: -15%;
                left: 55px;
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
                border-radius: @border-radius-base;
                top: auto;
            }
        }

        .sdk-features-list {
            list-style: none;
            position: relative;

            li {
                margin-top: 10px;
                line-height: 2;
                position: relative;

                &:first-child {
                    margin-top: none;
                }

                &:before {
                    content: "";
                    width: 12px;
                    height: 12px;
                    background-color: #F7D370;
                    display: inline-block;
                    position: absolute;
                    left: -25px;
                    top: 10px;
                    border-radius: 3px;
                }
            }
        }
    }
    /*====== Blog ======*/
    .blog-articles {
        margin-top: 90px !important;
    }

    .blog-section-title {
        margin-bottom: 35px;
    }

    .blog-card {
        position: relative;
        z-index: 10;
        height: 100%;
        border-radius: @border-radius-base;
        border: 1px solid @border-color-base;
        background-color: #fff;
        padding: 0;
        overflow: hidden;
        transition: 0.3s all ease;

        .card-body {
            padding: 0;
        }

        .blog-title {
            display: block;
            padding: 30px;
            background-color: #fff;
            font-size: 1.1em;
            font-weight: 500;
            color: @primary-color;
        }

        &:hover,
        &:focus {
            box-shadow: 0px 20px 35px 0px rgba(190, 195, 215, 0.6);
        }
    }
    /*====== Home Page Style ======*/
    .avepdf-bck-details {


        a {
            &:hover,
            &:focus {
                opacity: .75;
            }

            .link-arrow {
                display: inline-block;
                max-width: 16px;

                svg {
                    path {
                        fill: #1D7C76;
                    }
                }
            }
        }

        .avepdf-bck-details-img {
            text-align: center;

            svg {
                margin: 0 auto;

                @media (max-width: 768px) {
                    max-width: 320px;
                }
            }
        }
    }
    /*========== Special Background ==========*/
    .fancy-bg {

        .fancy-bg-1 {
            &:before {
                content: "";
                position: absolute;
                width: 64px;
                height: 64px;
                background: #f7d370;
                border-radius: @border-radius-base * 3;
                top: 4%;
                left: -2%;

                @media (max-width: 768px) {
                    left: -10%;
                }
            }

            &:after {
                content: "";
                position: absolute;
                width: 25px;
                height: 25px;
                background: #7340f3;
                border-radius: @border-radius-base * 2;
                top: -1%;
                left: 5%;
            }
        }

        .fancy-bg-2 {
            position: absolute;
            width: 36px;
            height: 36px;
            background: rgba(117, 199, 184, 0.2);
            border-radius: @border-radius-base * 2;
            top: 30px;
            right: 30px;
        }

        .fancy-bg-3 {
            &:before {
                content: "";
                position: absolute;
                width: 9px;
                height: 9px;
                background: #f7d370;
                border-radius: 50%;
                top: 20%;
                left: 50%;
            }

            &:after {
                content: "";
                position: absolute;
                width: 15px;
                height: 15px;
                background: #d63125;
                border-radius: 50%;
                top: 25%;
                left: 52%;
            }
        }

        .fancy-bg-4 {
            &:before {
                content: "";
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background: #EE9777;
                left: 10%;
                bottom: 15%;

                @media (max-width: 768px) {
                    left: 5%;
                }
            }

            &:after {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #F7D370;
                left: 13%;
                bottom: 12%;

                @media (max-width: 768px) {
                    left: 8%;
                }
            }
        }

        .fancy-bg-5 {
            position: absolute;
            width: 32px;
            height: 32px;
            background: #d63125;
            border-radius: @border-radius-base * 2;
            right: 10%;
            bottom: 20px;
        }
    }
    /*========== Testimonials ==========*/
    .testimonials-wrapper {
        background: #FDFDFF;
        box-shadow: 0px 40px 70px -20px rgba(0, 0, 0, 0.1);
        border-radius: @border-radius-base * 5;
        padding: 120px;
        margin-bottom: 60px;
        position: relative;

        @media (max-width: 768px) {
            padding: 60px 60px 80px 60px;
        }

        .slick-dots-bottom {
            bottom: -60px;
            margin-top: 20px;
        }

        .slick-dots {
            li {
                width: 26px;
                height: 10px;

                button {
                    background: #1D7C76;
                    height: 10px;
                    border-radius: @border-radius-base * 2;
                }

                &.slick-active {
                    button {
                        background: #1D7C76;
                    }
                }
            }
        }

        .testimonial-content {
            a {
                color: #1D7C76 !important;
                padding: 15px 0;

                .link-arrow {
                    display: inline-block;
                    max-width: 16px;

                    svg {
                        path {
                            fill: #1D7C76 !important;
                        }
                    }
                }
            }
        }
    }

    .ant-carousel {
        color: inherit;
    }
    /*====== OFFER TOOLS ON DOWNLOAD START ======*/
    .download-offer-tools {
        margin-top: 30px;
        padding-top: 30px;
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    .offer-after-procesed {
        text-align: initial;
        justify-content: center;
    }
    // Policy Protect
    .policy-protect-wrapper {

        .policy-protect-icon {

            svg path {
                fill: #ffffff;
            }
        }

        .policy-protect-content {
        }
    }
    /*====== HOW TO USE SECTION ======*/
    .how-to-use {

        .how-to-use-icon {
            text-align: center;
        }
    }
    /*====== DID YOU KNOW SECTION ======*/
    .did-you-know {
        border-bottom: 1px solid #dde5ee;
        max-width: 95%;
        margin: 0 auto;
        padding: 25px 5px;

        .did-you-know-href {
            color: #0388db;
            text-decoration: underline;

            &:hover {
                color: #0388db;
                text-decoration: underline;
            }
        }
    }
    /*====== Slider ======*/
    .ant-slider-rail {
        height: 8px;
        border-radius: @border-radius-base;
        width: calc(100% + 14px);
        margin-left: -7px;
    }

    .ant-slider-with-marks {
        margin-left: 0;
        margin-right: 0;
    }


    .ant-slider-track {
        height: 8px;
        border-radius: @border-radius-base;
    }

    .ant-slider-handle {
    }

    .ant-slider-handle,
    .ant-slider-handle:focus,
    .ant-slider-handle:hover {
        border-style: solid;
        border-width: 8px;
    }

    .ant-slider-handle:focus {
        outline: none;
        box-shadow: 0 0 0 5px rgba(31, 31, 31, .2);
    }

    .ant-slider-dot {
        border-style: solid;
        border-width: 8px;
        top: -5px;
        margin-left: 0px;
    }

    .ant-slider-dot:first-child,
    .ant-slider-dot:last-child {
    }

    .ant-slider-dot-active {
    }

    .ant-slider-mark-text {
        padding-top: 8px;
        color: inherit;
        color: @text-color;
        white-space: nowrap;
    }

    .ant-slider-mark-text-active {
        color: @text-color;
    }

    .text-align-center {
        text-align: center;
    }

    .back-icon {

        svg {
            vertical-align: inherit;

            path {
                fill: @primary-color;
            }
        }
    }

    .parent {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .horizontal-tool {
        margin-bottom: 38px;
    }

    .horizontal-tool {
        .horizontal-tool-description {
            font-size: 15px;
            color: @text-color-secondary;
        }

        .horizontal-tool-title {
            font-size: 17px;
            font-weight: bold;
            color: @text-color;
        }

        .horizontal-tool-icon-svg {
            display: block;
        }

        .horizontal-tool-icon svg {
            height: 100%;
            width: 100%;
        }
    }

    .card-block {
        padding-right: 22px;
        padding-left: 22px;
    }

    .protect-password .ant-input-prefix {
        padding-top: 5px;
    }

    .ant-input-affix-wrapper {
        background-color: #fff;
        border-radius: @border-radius-base;
        box-shadow: none;
        transition: none;

        .ant-input:hover,
        .ant-input:focus {
            border: none;
        }

        &:hover,
        &.ant-input-affix-wrapper-focused {
            border-color: rgba(102,110,132,.4);
            -webkit-box-shadow: none;
        }

        .ant-input {
            border-radius: @border-radius-base;
            border: none;
        }
        /* Change Autocomplete styles in Chrome*/
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            box-shadow: 0 0 0px 1000px #fff inset !important;
        }




        .ant-input-prefix {
            text-align: center;
            width: 20px;

            img {
                width: 17px;
            }
        }

        .ant-input:not(:first-child) {
            padding-left: 10px;
        }
    }

    .tool-item-wrapper:hover {
        position: relative;

        .tool-item-block {
            box-shadow: 0px 40px 60px -20px rgba(102, 110, 132, 0.15);
        }
    }

    .tool-item-content:hover {
        box-shadow: 0 14px 14px 0 rgba(77, 113, 173, 0.06);
    }

    .document {
        max-width: 390px;
        border: 1px solid rgba(160, 181, 206, 0.34);
        height: 96px;
        border-radius: @border-radius-base;
        background-color: #ffffff;
        position: absolute;
        bottom: 10px;
        left: 2%;
        width: 74%;
        line-height: 1.4;

        .horizontal-tool-title {
            font-size: 16px;
            font-weight: 600;
            color: @text-color;
        }

        .horizontal-tool-description {
            font-size: 14px;
            color: @text-color-secondary;
            line-height: 1.5;
        }
    }


    .vertial-auto-margin {
        margin-top: auto;
        margin-bottom: auto;
    }

    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ant-input {
        border-radius: @border-radius-base;
        height: 52px;
        font-size: 15px;
        line-height: 1.38;
        color: @primary-color;
    }


    .ant-progress-success-bg,
    .ant-progress-bg,
    .ant-progress-status-success .ant-progress-bg {
        background-color: #4a90e2;
    }

    .upload-progress > .ant-progress-text {
        color: @primary-color;
    }

    .upload-progress > .ant-progress-text svg {
        display: none;
    }

    .ant-progress-outer {
        padding-right: calc(2em + 8px) !important;

        @media (max-width: 575.98px) {
            width: 95%;
        }
    }

    .upload-progress-wrapper {
        position: relative;
        width: 100%;
    }

    .upload-progress {
        margin: 36px auto 0 !important;
        width: 70% !important;
        display: block !important;

        @media (max-width: 575.98px) {
            width: 60% !important;
        }
    }

    .upload-progress-loading {
        width: 40px;
        position: absolute;
        right: 10.5%;
        top: -18%;
        fill: @primary-color;
    }

    .upload-progress-almost-there {
        margin: 0;
        text-align: center;
        margin-top: 10px;
        min-height: 30px;
        min-width: 20px;
    }

    .drag-enter,
    .drag-over {
        background-color: rgba(148, 191, 218, 0.03);
    }

    .loading-icon {
        width: 80px;
        height: 90px;
        margin-right: 42px;
    }
    /* WIN10 LOADER */
    .loader-content {
        position: relative;
        height: 100vh;
    }

    .loader {
        display: block;
        left: 50%;
        margin-top: -180px;
        position: relative;
        padding-top: 100px;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100px;

        .circle {
            position: absolute;
            width: 80px;
            height: 80px;
            opacity: 0;
            transform: rotate(225deg);
            animation-iteration-count: infinite;
            animation-name: orbit;
            animation-duration: 4s;

            &:after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 500px;
                background: @text-color;
            }

            .circle-generator(@position) {
                &:nth-child(@{position}) {
                    animation-delay: unit((@position - 1) * @component-loader-duration, ms);
                }
            }

            .circle-generator(2);
            .circle-generator(3);
            .circle-generator(4);
            .circle-generator(5);
        }
    }

    .loading-tool {
        margin: 0 auto;
    }

    .loading-tool .loading-icon {
        margin-right: 0;
    }

    .upload-file-wrapper .loading-icon {
        margin: 0 auto;
    }

    .rotate-row {
        margin-right: 0;
        margin-left: 0;
    }

    .ave-btn.rotate-btn {
        display: flex;
        height: 45px;
        width: 10rem;
        min-width: 140px;
        margin: auto 0;
        padding: 0 1em !important;
        border: 1px solid transparent;
        font-size: 16px;
        font-weight: 600;
    }

    .ant-btn.rotate-btn {

        svg {
            width: 16px;
            height: 16px;
            margin-left: 5px;

            path {
                fill: inherit;
            }
        }

        &:hover,
        :focus {
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }

    .rotate-btn span,
    i {
        margin: auto;
    }

    .rotate-btn:hover {
    }

    @media not all and (pointer: coarse) {

        .rotate-btn:hover {
        }
    }

    .rotate-btn:focus {
        svg {
            path {
                fill: inherit;
            }
        }
    }

    .w-100 .tools-rotate-row .rotate-row .rotate-btn-left {
        margin-left: 10px;
        border-radius: @border-radius-base 0 0 @border-radius-base;
    }

    .w-100 .tools-rotate-row .rotate-row .rotate-btn-right {
        border-radius: 0 @border-radius-base @border-radius-base 0;
    }

    .w-100 .tools-rotate-row .rotate-row .rotate-restore {
        margin: auto 10px;
    }

    @media (max-width: 768px) {
        .w-100 .tools-rotate-row .rotate-row .rotate-restore {
            margin: 10px 0;
        }

        .w-100 .tools-rotate-row .rotate-row .rotate-btn-left {
            margin-left: 0;
        }
    }

    .download-action-button p,
    .download-action-button .download-button-text-block .download-size span {
        font-weight: bold;
    }

    .download-action-button .download-button-text-block {
        display: inline-block;
    }

    .download-action-button .download-button-text-block p {
        font-weight: bold;
        margin-bottom: 0px;
        line-height: 1.4;
        text-align: center;
    }

    .download-action-button .download-button-text-block .download-size {
        display: block;
        font-size: 12px;
        white-space: pre-wrap;
    }

    .protect-pdf-password {
        width: 30%;
    }

    .resize-input-w {
        width: 30%;
        text-align: center;
    }

    .resize-input-h {
        width: 30%;
        text-align: center;
    }

    .protect-pdf-password.red-icon svg path,
    .protect-pdf-password-input.red-icon svg path {
        fill: red !important;
    }

    .full-preview-elem {
        outline: 2px solid #efefef;
    }

    #esign-selection-box {
        border: 1px dotted @primary-color;
        background: rgba(209, 209, 209, 0.4);
        position: absolute;
    }

    .full-preview-page {
        position: relative;
        margin-bottom: 10px;
    }

    .crop-wrapper {
        padding: 0 10px;
    }

    .filter-div {
        position: absolute;
        top: 0;
        left: 0;
    }

    .ant-modal {
        max-width: 992px;
    }



    .premium-modal-img {
        max-width: 240px;
        margin: 0 auto 30px;
    }

    .ant-modal-confirm-body {
        .ant-modal-confirm-title {
            color: @primary-color;
        }
    }

    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
        margin-left: 3px;
        margin-top: 20px;
    }

    .remeber-input {
        position: relative;
    }

    .remeber-input .ant-checkbox {
        position: absolute;
        top: 2px;
    }

    .remeber-input .ant-checkbox-wrapper + span,
    .remeber-input .ant-checkbox + span {
        display: block;
        padding-left: 35px;
        font-size: 12px;
    }

    .esign-wrapper {
        text-align: center;
        width: 100%;
        margin: 0 10px;
    }

    .signature-wrapper {
        position: relative;
    }

    .signature-wrapper-text {
        position: relative;
        z-index: 1000;
    }

    .signature-wrapper span.clear-signature {
        text-decoration: underline;
        cursor: pointer;
        position: absolute;
        bottom: 15px;
        left: 15px;
        font-size: 16px;
        font-weight: bold;
    }

    #signature canvas {
        background-color: #f3f3f3 !important;
        height: 400px !important;
    }

    .signature-image {
        margin: 0px;
        padding: 0px;
        border: none;
        width: 100%;
        touch-action: none;
        background-color: #f3f3f3;
        height: 400px !important;
    }

    .signature-image,
    .signature-image img {
        max-height: 380px;
    }

    .signature-image img {
        max-width: 100%;
    }

    .signature-modal-button {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .signature-button-wrapper {
    }

    #full-preview-group {
        width: 100%;
        position: relative;
    }

    .crop-box {
        position: absolute;
        z-index: 10;
        border: 2px dotted @primary-color;
        background: #fff;
        opacity: 0.6;
    }
    /*====== Crop Modal ======*/
    .crop-modal .ant-modal-body {
        padding: 0px;
    }

    .crop-modal .ant-modal-footer {
        padding: 16px 16px;
    }

    .crop-modal .signature-modal-button {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    /*====== InputNumber (disable native & useless HTML arrow) ======*/
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    /*====== Redaction Modal ======*/
    .redaction-modal-warning {
        color: red;
        margin: 10px 0px;
        text-align: start;
    }
    /*====== Signature modal  ======*/
    .signature-modal .action-button {
        padding: 10px 7%;
        min-width: auto;
        height: auto;
        margin: 5px 10px 5px 0;
    }

    .signature-wrapper {
        max-width: 94%;
        margin: 0 auto;
        top: 12px;
    }
    /* favorite icon */
    /*default state*/
    .heart-icon {
        opacity: 0;
    }

    .active .heart-icon {
        opacity: 1;
    }

    .pdf-to-jpg-quality-wrapper {
        color: @primary-color;
        display: flex;
        flex-direction: row;
        width: max-content;
        font-size: 16px;
    }
    //Page Numbers Tool
    .pagenumber-preview-wrapper {
        position: relative;
        width: 80%;
        max-width: 320px;
        display: block;
        margin: 15% auto;
        border: 6px solid rgba(160, 181, 206, 0.34);
        border-radius: @border-radius-base * 4;
        padding: 45px 15px;

        .preview-text-position {
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            max-width: 80%;
            border: 1px dashed @primary-color;
            padding: 5px 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;


            &.position-top-left {
                top: 10px;
                left: 16px;
            }

            &.position-top-center {
                top: 10px;
                margin: 0 auto;
                left: 0;
                right: 0;
                text-align: center;
                max-width: 80%;
            }

            &.position-top-right {
                top: 10px;
                right: 16px;
            }

            &.position-bottom-left {
                bottom: 10px;
                left: 16px;
            }

            &.position-bottom-center {
                bottom: 10px;
                margin: 0 auto;
                left: 0;
                right: 0;
                text-align: center;
                max-width: 80%;
            }

            &.position-bottom-right {
                bottom: 10px;
                right: 16px;
            }

            &.position-center-left {
                top: 50%;
                left: 16px;
            }

            &.position-center-right {
                top: 50%;
                right: 16px;
            }

            &.position-center-center {
                top: 50%;
                margin: 0 auto;
                left: 0;
                right: 0;
                text-align: center;
                max-width: 80%;
            }

            &.watermark-position {
                width: 120px;
                height: 120px;

                &.position-center-left {
                    margin-top: -60px;
                }

                &.position-center-right {
                    margin-top: -60px;
                }

                &.position-center-center {
                    top: 50%;
                    margin: -60px auto 0;
                    left: 0;
                    right: 0;
                }

                img {
                    width: auto;
                    height: auto;
                    position: absolute;
                    max-width: 100%;
                    max-height: 100%;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    left: 0;
                    right: 0;
                }
            }
        }

        &.watermark-preview {
            max-width: 100%;
            min-height: auto;
            height: auto;
            margin: 0 auto;
            width: 100%;
            max-height: 100%;
            border-width: 3px;
        }
    }

    .preview-content-img {
        width: 90%;
        height: 90%;
        margin: 5%;
        position: relative;
        top: 0;
        bottom: 0;

        img {
            width: 100%;
            height: auto;
        }

        svg {
            width: 100%;
            height: 100%;

            path {
                fill: @primary-color;
            }
        }
    }
    //Barcode Tool
    .barcode-number {
        padding: .25rem .5rem;
        border-radius: @border-radius-base;
        background: @primary-color;
        margin: 0;
        text-align: center;
        min-width: 32px;
        color: #fff;
    }
    /*----------------------
    Watermark
    -----------------------*/
    .select-watermark {
        button {
            svg {
                margin-right: 10px;
            }

            &:hover,
            &:focus,
            &:active {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .ant-btn {
            @media (max-width: 767px) {
                white-space: break-spaces;
            }
        }

        .ant-upload-list-item {
            height: auto;

            .ant-upload-span {
                padding: 10px;
            }
        }

        .ant-upload-list-item-info {
            border-radius: @border-radius-base;
        }

        .ant-upload-list-item-card-actions-btn {
            svg {
                margin: 5px;
            }
        }

        .ant-radio-button-wrapper {
            margin: 0 5px 5px 0 !important;
            border-radius: @border-radius-base !important;
            border: 1px solid #d9d9d9;

            @media (max-width: 767px) {
                height: auto;
                font-size: 15px;
                line-height: 1.8;
                padding: 8px;
            }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            display: none !important;
        }

        .text-color {
            line-height: 1 !important;
            padding: 7px;
            vertical-align: middle;
            margin: 0px 5px 5px 5px !important;
            background-color: transparent;

            span {
                width: 24px;
                height: 24px;
            }
        }

        @media (max-width: 767px) {
            text-align: start;
        }
    }

    .color-hue {
        margin-bottom: 0 !important;
        width: 225px !important;
    }

    .color-saturation {
        width: 225px !important;
        margin-bottom: 15px !important;
    }

    .watermark-preview-image-small {
        width: 32px !important;
        height: auto !important;
    }

    .watermark-preview-image-medium {
        width: 64px !important;
        height: auto !important;
    }

    .watermark-preview-image-large {
        width: 96px !important;
        height: auto !important;
    }

    .below-layer-svg, .above-layer-svg {
        display: inline-block;
        margin-right: 10px;
        max-width: 14px;

        svg {
        }
    }
    /*----------------------
    PDF/A Validation
    -----------------------*/
    .validation-report-details {
        padding: 15px;
        margin: 15px 0;
        border: 1px solid rgba(102,110,132,.2);
        background-color: rgba(234,234,250,.08);
        border-radius: @border-radius-base;

        .pdfa-xml-result {

            &.ant-collapse {
                background: #fff9f9;
                border: 1px solid rgba(102,110,132,.2);

                .ant-collapse-item {
                    border-color: rgba(102,110,132,.2);

                    .ant-collapse-header {
                        color: #c62828;
                    }

                    .ant-collapse-content {
                        border-color: rgba(102,110,132,.2);

                        .report-error-occurence {
                            span:first-child {
                                color: #0091ea;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    // Tables
    //responsive tables
    .averes-table {
        margin: 1em 0;
        min-width: 100%;

        tr {
        }

        th {
            display: none;
        }

        tbody {
            border-top: 1px solid @border-color-base;

            &:first-child {
                border-top: 2px solid @border-color-base;
            }

            &:before {
                content: '';
                display: block;
                height: 15px;
            }
        }


        td {
            display: block;

            &:before {
                content: attr(data-th);
                font-weight: bold;
                display: inline-block;
                margin-right: .5rem;

                @media (min-width: 767px) {
                    display: none;
                }
            }
        }

        th, td {
            text-align: start;

            @media (min-width: 767px) {
                display: table-cell;
                padding: .25em .5em;
            }
        }
    }
    // Code
    code {
        color: @primary-color;
        background: #fff;

        &.info-code {
            display: block;
            width: 100%;
            border: 1px solid @border-color-base;
            border-radius: @border-radius-base;
            padding: 15px 15px 40px;
            margin: 10px 0;
            word-wrap: anywhere;
        }
    }

    .barcode-info-wrapper {
        position: relative;

        td {
            position: relative;
            padding: 0;
        }

        .ant-btn {
            position: absolute;
            padding: 5px 15px;
            border-radius: @border-radius-base 0;
            right: 0;
            bottom: 10px;

            @media (max-width: 767.98px) {
                bottom: 0;
            }
        }
    }

    .barcode-document-preview-wrapper {
        width: 100%;
        height: 540px;
        border: 1px solid @border-color-base;

        @media (max-width: 767.98px) {
            height: 335px;
        }
    }
    /*===============================
========== 16. Responsive Breakpoints ==========
===============================*/

    @media (min-width: 1200px) {

        .container {
            max-width: 1170px;
        }


        .second-slider {
            margin-left: 2rem;
            margin-right: 2rem;
        }
    }

    .protect-warning-label {
        font-size: 14px;
        color: #c23321;
        padding-left: 20px;
    }

    .tooltip-icon {
        vertical-align: middle;
        height: 1.2em;
        width: auto;
        margin-top: -0.2em;
    }

    .protect-pdf-dropdown-wrapper {
        padding-bottom: 20px;
    }

    .protect-checkbox-item {
        padding-left: 0px;
        padding-right: 0px;
    }

    .protect-pdf-printing-dropdown {
        width: 100%;
    }

    .protect-pdf-mode-description {
        font-size: 14px;
    }

    .protect-mode-col {
        padding: 40px;
    }

    .protect-pdf-subtitle {
        font-size: 20px !important;
    }

    .head-label {
        position: relative;
        padding-bottom: 20px;

        &.head-label-tooltip {
            display: inline-block;
            margin: 0 auto;
            padding-right: 20px;
            padding-left: 20px;

            .head-label-tooltip-icon {
                position: absolute;
                top: 5px;
                right: 0;

                @media (max-width: 419.98px) {
                    right: auto;
                    left: 0;
                }
            }

            @media (max-width: 419.98px) {
                padding-right: 0;
                padding-left: 25px;
                display: block;
                text-align: start;
            }
        }
    }

    .resolution-menu-label {
        display: flex;
        padding-bottom: 20px;
        justify-content: center;
    }

    .resolution-menu-button-bar {
        justify-content: center;
        display: flex;
    }

    .keep-resolution-checkbox {
        padding-top: 20px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
    }

    .keep-resolution-pdf-to-tiff-checkbox {
        padding-top: 20px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
    }

    .keep-resolution-pdf-to-jpeg-checkbox {
        padding-top: 5px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
    }

    .keep-resolution-pdf-to-jpeg-switch {
        padding-top: 5px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
    }


    .security-options-block {
        margin: 0 15px 45px;
        padding: 40px 30px 40px 30px;
        border: 1px solid rgba(160, 181, 206, 0.34);
        border-radius: @border-radius-base;
    }

    .protect-password-label {
        display: inline;
    }

    .protect-pdf-advanced-mode-container {
        margin: 48px auto 0;
    }

    .protect-pdf-select-block {
        align-items: center;
    }

    .protect-pdf-password-block {
        align-items: center;
        font-weight: bolder;
    }


    .protect-pdf-block-title {
        font-size: 18px;
        background-color: fade(mix(rgb(160, 188, 222), #ffffff, 3%), 100%);
        width: max-content;
    }

    .protect-pdf-switch {
        align-items: center;
    }

    .thumbnail-paste-page-wrapper {
        top: -34px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        overflow: hidden;
    }

    .organize-page-divider {
        width: 8px;
        height: 16rem;
        background-color: cornflowerblue;
        border: 1px solid cornflowerblue;
        border-radius: @border-radius-base * 6;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .page-paste-icon svg {
        width: 30px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        line-height: 1;
    }

    .organize-image-wrapper,
    .unable-load-page-preview {
        width: auto;
    }

    .default-organize-thumbnail {
        margin: 0px 32px 35px 32px !important;
    }

    .eqbox-wrapper-organize {
        width: 200px;
    }

    .add-page-btn {
        background-color: #fff;
        border-color: rgba(102,110,132,.2);
        position: absolute;
        top: 50%;
        margin-top: -21px;
        max-width: 42px;
        padding: 5px;
        z-index: 10;

        &.add-page-left {
            left: -21px;
        }

        &.add-page-right {
            right: -21px;
        }
    }
    /* Large devices (desktops, less than 1200px) */
    @media (max-width: 1199px) {

        .resolution-menu-button-bar {
        }

        .protect-pdf-padding-right {
            padding-right: 15px;
        }

        .protect-pdf-divider {
            width: 100% !important;
            padding-bottom: 40px !important;
        }

        .protect-pdf-left-block {
            padding-right: 40px;
        }

        .protect-checkbox-item {
            padding-right: 15px;
        }

        .protect-pdf-left-row {
            padding-bottom: 40px;
        }

        .protect-pdf-tooltip-icon {
            padding-left: 3px;
        }



        .markup-docuviewer-control div div :last-child .dvUi-resizable {
            width: 30% !important;
        }

        .resolution-label {
            text-align: center !important;
        }

        .quality-label {
            text-align: center !important;
        }

        .pdf-to-jpg-quality-wrapper {
        }

        .slider-margin {
            margin-right: 38px !important;
        }

        .second-slider {
            margin-left: 0px;
            margin-right: 0px;
        }

        .flatten-col {
            margin-bottom: 30px;
        }

        .flatten-slider {
            display: block !important;
        }


        .flatten-slider-last {
            width: 100%;
        }

        .flatten-col {
            max-width: 100%;
        }

        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
            left: -10px;
            width: 40px;
        }

        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
            right: -10px;
            width: 40px;
        }

        .sign-form .forgot-pass {
            text-align: start;
            float: none;
            display: block;
        }

        .grecaptcha-badge {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            bottom: 0 !important;
        }
        /*.thumbnail-part {
        width: 24%;
    }*/
        .tools-sort .tool-item-wrapper {
            width: 24%;
        }

        .tool-item-content {
            width: 185px;
            height: 185px;
        }

        .small-icons .tool-item-content {
            width: 120px;
            height: 120px;
        }

        .small-icons .tool-item-content .tool-item-description span {
            font-size: 10px;
        }

        .coming-soon {
            font-size: 10px;
            line-height: 16px;
        }

        .split-choose-mode {
            width: 60%;
        }

        .signature-modal .ant-modal {
            max-width: 96%;
        }

        .crop-modal .ant-modal {
            max-width: 96%;
        }
    }

    .crop-modal .ant-modal {
        top: 10px;
    }

    .select-crop-options {
        cursor: pointer;
        font-weight: bold;
        line-height: 1.43;
        color: #041632;
        margin-right: 20px;
        border: none;
    }

    .crop-wrapper {
        text-align: center;
        width: 100%;
        margin: 0 10px;
    }
    /* Medium devices (tablets, less than 992px)  */
    @media (max-width: 991px) {

        .pdf-to-jpg-quality-wrapper {
        }


        .keep-resolution-pdf-to-jpeg-checkbox {
            padding-top: 0px !important;
        }

        .keep-resolution-pdf-to-tiff-checkbox {
            padding-top: 0px !important;
        }

        .resolution-menu-button-bar {
        }


        .protect-checkbox-item {
            padding-left: 15px;
            padding-right: 15px;
        }

        .resolution-label {
            padding-bottom: 25px;
        }

        .slider-margin {
            margin-right: 38px !important;
        }

        .second-slider {
            margin-left: 2rem;
            margin-right: 2rem;
        }

        .flatten-col {
            margin-bottom: 0px;
        }

        .flatten-slider {
        }

        .first-flatten-col {
            margin-left: 0px;
        }

        .flatten-slider-last {
            width: auto;
        }

        .main-wrapper {
            display: block;
            width: 100%;
        }

        .main-content-wrapper {
            /*padding: 60px 0;*/
        }


        .navbar-collapse {
            padding: 0;
        }

        .navbar-nav > li > a {
            line-height: 1.375rem;
        }

        .header-desktop {
            border-right: none;
            border-bottom: 1px solid #dfe6ec;
            padding-right: 0;
        }

        .navbar-btn {
            display: inline-block;
            width: 49%;
            margin: 15px 0 !important;
            padding: 18px 8px;
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }

        .desktop-app-screen {
            border-top: none;
            border-left: 1px solid #efefef;
        }

        .passport-tools-dropdown {
            position: relative !important;
            top: 0 !important;
        }

        .btn.btn-icon {
            margin: 0;
        }

        .hr-vertical {
            position: relative;
            height: 40px;
            margin: 10px 0;
        }

        .hr-vertical:before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #546c96;
            position: absolute;
            top: 50%;
            left: 0;
        }

        .hr-vertical .hr-vertical-text {
            position: absolute;
            top: 0;
            width: 40px;
            text-align: center;
            margin: 0 auto;
            left: 0;
            right: 0;
            font-size: 1.286em;
            height: 40px;
            line-height: 40px;
            background-color: #ffffff;
        }

        #account-options-title {
            margin-bottom: 15px;
        }

        .account-options-title-wrapper {
            border: none;
        }

        .my-profile-nav {
            min-height: 0;
        }

        .my-profile-nav-toggle {
            font-size: 1rem;
            margin: 0;
            float: none;
            vertical-align: middle;
            border: 1px solid #dfe6ec !important;
            width: 100%;
            margin-bottom: 10px;
        }

        .social-list {
            text-align: center;
        }

        .copyright {
            line-height: inherit;
        }

        .language-select {
            text-align: center;
        }

        .powered-by-wrapper {
            text-align: start;
            margin-top: 5px;
        }
        /*.thumbnail-part {
        width: 48%;
    }*/
        .tools-sort .tool-item-wrapper {
            width: 31.33%;
        }

        .tool-item-content {
            width: 170px;
            height: 170px;
        }

        .tool-item-content .tool-item-description span {
            font-size: 14px;
        }

        .small-icons .tool-item-content {
        }

        .small-icons .tool-item-content .tool-item-description span {
            font-size: 9px;
        }

        .rotate-apply-button {
        }

        .coming-soon {
            font-size: 9px;
            line-height: 14px;
        }

        .document {
            width: 80%;
        }

        .split-choose-mode {
            width: 80%;
        }

        .not-found-header {
            font-size: 9em;
        }
    }
    /* Small devices (landscape phones, less than 768px) */
    @media (max-width: 767px) {


        .btn-filters-pdf-to-jpeg .btn {
            width: 46%;
            margin: 0px !important;
            border-radius: @border-radius-base !important;
        }

        .resolution-menu-button {
            width: 100% !important;
        }

        .protect-pdf-left-row {
            padding-bottom: 10px;
        }

        .protect-pdf-left-col {
            flex-basis: 0 !important;
        }

        .protect-checkbox-item {
            padding-right: 0px;
        }

        .protect-pdf-dropdown-wrapper {
            padding-left: 0px;
        }

        .protect-pdf-label-row {
            padding-bottom: 20px;
        }

        .protect-pdf-left-block {
            padding-left: 25px;
            padding-bottom: 30px;
        }

        .protect-pdf-left-row {
            display: flow-root;
        }

        .protect-checkbox-item {
            padding-right: 15px;
            padding-bottom: 25px;
        }

        .protect-mode-col {
            justify-content: center !important;
        }

        .markup-docuviewer-control div div :last-child .dvUi-resizable {
            width: 40% !important;
        }

        .slider-margin {
            margin-right: 38px !important;
        }

        .second-slider {
            margin-left: 0px;
            margin-right: 0px;
        }

        .flatten-col {
            margin-bottom: 30px;
        }

        .flatten-slider-last {
            width: auto;
        }

        .first-flatten-col {
            margin-left: 0px;
        }

        .flatten-slider {
            display: block !important;
        }

        .ant-input-number {
            height: 44px;
            width: 70px;
        }

        .tools-link {
            text-align: center;
            display: block;
            border: 2px solid !important;
        }

        .esign-mode-buttons {
            text-align: center !important;
            padding-bottom: 20px;
        }

        .text-align-right {
            text-align: center !important;
        }

        .did-you-know-subtitle {
            height: auto !important;
        }

        .margin-left-50 {
            padding-left: 5% !important;
            text-align: start !important;
            margin: 0px !important;
        }

        .centred-resize-text {
            padding-top: 20px;
            text-align: start !important;
        }

        .centred-resize-text > div.pt-4.pb-5 {
            text-align: center !important;
        }

        .margin-right-30 {
            width: 40% !important;
            margin-right: 15px !important;
        }

        .margin-left-30 {
            width: 40% !important;
            margin-left: 15px !important;
        }

        .resize-controls {
            width: 100% !important;
        }

        .passport-tools-menu {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        .passport-tools-menu-open {
            position: relative;
            padding: 15px 0;
        }

        .nav-profile > li.active > a:before {
            display: none;
        }

        .nav-profile > li.active > a:after {
            display: none;
        }

        .my-profile-nav .nav > li {
            margin-bottom: 0.3571rem;
        }

        .account-settings-options {
        }

        .section-row {
            padding: 30px 0;
        }

        table.dataTable > tbody > tr.child ul.dtr-details {
            font-size: 0.6875rem;
        }

        div.dataTables_wrapper div.dataTables_paginate ul.pagination {
            justify-content: center;
        }

        .lead.lead-small {
            font-size: 0.875rem;
        }

        .contact-send {
            text-align: start;
            margin-top: 0;
        }

        .language-select .dropdown-menu {
            left: -5px;
        }

        .end-account {
            margin-bottom: 1rem;
        }

        .tools-sort .tool-item-wrapper {
            width: 49%;
        }

        .btn {
            margin-bottom: 15px;
        }

        .horizontal-tool .horizontal-tool-icon {
            width: 100%;
            max-width: 40%;
            margin: auto;
            text-align: center;
        }

        .horizontal-tool .horizontal-tool-text {
            text-align: center;
            margin-top: 10px;
        }

        .tool-item-content {
            width: 150px;
            height: 155px;
        }

        .small-icons .tool-item-content {
            width: 150px;
            height: 155px;
        }

        .small-icons .tool-item-content .tool-item-description span {
            font-size: 14px;
        }

        .small-icons .tool-item-icon img {
            width: 60%;
        }

        .coming-soon {
            font-size: 16px;
            line-height: 24px;
            top: 18px;
            right: -22px;
        }

        .powered-by-wrapper {
            text-align: center;
            margin-top: 5px;
        }

        .footer-text {
            text-align: center;
        }

        .split-choose-mode {
            width: 100%;
        }

        .split-mode {
            width: 48%;
        }

        .split-instructions {
            display: none;
        }

        .split-instructions-mobile {
            display: block;
        }

        .action-button,
        .close-action-button,
        .simple-modal-error-wrapper button {
        }
        /*----------------------
    Crop modal 
    -----------------------*/
        /*----------------------
    Signature modal 
    -----------------------*/
        .signature-modal .ant-modal {
            top: 0;
        }

        #signature canvas {
            height: 260px !important;
        }

        .ant-slider-with-marks {
            width: 85%;
            margin: 14px auto 28px;
        }

        .document {
            height: auto;
            left: 2%;
            bottom: 64px;
            width: 96%;
            padding: 4px 0;
        }

        #widget-content {
            .document {
                bottom: 20px !important;
            }
        }

        .document .card-block {
            padding-left: 0;
        }

        .document-icon {
            width: 100%;
            height: auto;
            margin: 10px auto;
        }
        /***********************
        TEXT TO PDF START
    ***********************/
        .margins-icon-wrapper {
            width: 250px;
        }

        .margins-icon-svg {
            width: 200px;
        }

        .margin-icon-value {
            &.margin-top {
                left: 90px;
            }

            &.margin-bottom {
                left: 90px;
            }

            &.margin-left {
                left: -15px;
            }

            &.margin-right {
                right: -15px;
            }
        }
        /***********************
        TEXT TO PDF END
    ***********************/
        /************************
        HEADER START
    ***********************/
        .auth-buttons {
            display: none;
        }
        /************************
        HEADER END
    ***********************/
        .zEWidget-launcher {
            bottom: 65px !important;
            right: -12px !important;
        }
        /*----------------------
    PDF to JPG
    -----------------------*/
        .protect-pdf-advanced-mode-container {
            margin: 48px auto 0;
        }
    }
    /* Extra small devices (portrait phones, less than 576px) */
    @media (max-width: 575px) {

        .ant-slider-with-marks {
            width: 70%;
        }

        .protect-pdf-left-col {
            padding-left: 41px;
        }

        .slider-margin {
            margin-right: 38px !important;
        }

        .second-slider {
            margin-left: 0px;
            margin-right: 0px;
        }

        .flatten-slider-last {
            width: auto;
        }

        .first-flatten-col {
            margin-left: 0px;
        }

        .navbar-default .navbar-brand {
            width: 220px;
        }

        .navbar-default .navbar-brand span {
            margin-left: 58px;
            font-size: 0.75em;
            position: relative;
        }

        .nav-tabs .nav-link {
            border: 1px solid #dee2e6;
            margin-bottom: 10px;
            border-radius: 0.25rem;
        }

        .nav-tabs .nav-link.active,
        .nav-tabs .nav-item.show .nav-link {
            border-color: #dee2e6;
            background-color: #f2f2f2;
            border-radius: 0.25rem;
        }

        .tool-item {
            margin: 5px;
        }

        .btn {
            margin-bottom: 15px;
        }

        .btn.btn-alt {
            padding: 16px 8px;
        }

        .input-group-btn > .btn {
            margin-bottom: 0px;
        }

        .btn-sm,
        .btn-group-sm > .btn {
            padding: 14px 14px;
        }

        .main-slider .carousel-inner .carousel-item-content .caption-content .btn-group > .btn,
        .main-slider .carousel-inner .carousel-item-content .caption-content .btn-group-vertical > .btn {
            margin-bottom: 0;
        }

        .main-slider .carousel-inner .carousel-item-content .caption-content .btn-group > .btn,
        .main-slider .carousel-inner .carousel-item-content .caption-content .btn-group-vertical > .btn {
            padding: 12px 16px !important;
        }

        .main-header {
            -webkit-background-size: cover !important;
            /* For WebKit*/
            -moz-background-size: cover !important;
            /* Mozilla*/
            -o-background-size: cover !important;
            /* Opera*/
            background-size: cover !important;
            /* Generic*/
        }

        code,
        kbd,
        pre,
        samp {
            word-wrap: break-word;
        }

        #rc-imageselect,
        .g-recaptcha {
            transform: scale(0.77);
            -webkit-transform: scale(0.77);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }

        .btn.g-recaptcha {
            transform: scale(1);
            -webkit-transform: scale(1);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }

        .generic-error-wrapper .panel-border {
            -webkit-background-size: 100% auto !important;
            /* For WebKit*/
            background-size: 100% auto !important;
            /* Generic*/
        }

        .end-account li {
            border-left: none;
            padding-left: 0;
        }

        .sign-form .forgot-pass {
            float: none;
            display: block;
        }

        .footer-menu li {
            margin-bottom: 0.9375rem;
        }

        .thumbnail-part {
            width: 100%;
            height: 50%;
            margin: 10px auto;
            max-width: 90%;
            padding: 40px 10px 10px;
            display: block;
        }

        .thumbnail-image-wrapper {
            min-height: 190px;
        }

        .thumbnail-number {
            margin: 0px;
        }

        .thumbnail-image-wrapper,
        .unable-load-page-preview {
            width: 100%;
        }

        .thumbnail-image-wrapper canvas,
        .unable-load-page-preview {
            width: 210px;

            @media (max-width: 767.98px) {
                padding: 15px;
            }
        }

        .protect-pdf-password {
            width: 80%;
        }

        .protect-pdf-confirm-password {
            margin-left: 0;
            margin-top: 15px;
        }

        .action-button.rotate-apply-button {
            display: inline-block;
        }

        .split-choose-mode {
            flex-direction: column;
        }

        .split-mode {
            width: 55%;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        .not-found-header {
            font-size: 8em;
            line-height: 80px;
        }

        .upload-file-methods-small {
            display: block !important;
        }

        .action-button,
        .close-action-button,
        .simple-modal-error-wrapper button {
        }


        #successArea {
            .downloaded-wrapper {
                padding-bottom: 0;
                margin-bottom: 20px;
            }
        }

        .horizontal-tool {
            margin-bottom: 30px;
        }

        .horizontal-tool .horizontal-tool-description {
            font-size: 13px;
            line-height: 1.5;
        }

        .horizontal-tool .horizontal-tool-icon {
            max-width: 100%;
        }

        .horizontal-tool .horizontal-tool-text {
            text-align: start;
            margin-top: 0;
        }
        /*----------------------
    Crop modal 
    -----------------------*/
        /*----------------------
    Extract PDF Pages 
    -----------------------*/
        /*----------------------
    PDF to JPG
    -----------------------*/
        .pdf-to-jpg-quality {
        }

        .keep-resolution-pdf-to-tiff-checkbox,
        .keep-resolution-pdf-to-jpeg-checkbox {
            padding: 0 10px 10px;
        }

        .keep-resolution-pdf-to-tiff-checkbox .fas,
        .keep-resolution-pdf-to-jpeg-checkbox .fas {
            display: initial;
        }
    }
    /* Extra very small devices (portrait phones, less than 376px) */
    @media (max-width: 375px) {


        .markup-docuviewer-control div div :last-child .dvUi-resizable {
            width: 45% !important;
        }

        .pdf-to-jpg-quality-wrapper {
        }

        .slider-margin {
            margin-right: 0px !important;
        }

        .second-slider {
            margin-left: 0px;
            margin-right: 0px;
        }

        .flatten-slider-last {
            width: auto;
        }

        .first-flatten-col {
            margin-left: 0px;
        }

        .end-account li {
            border-left: none;
            padding-left: 0;
        }

        .action-button.rotate-apply-button {
            display: inline-block;
            margin: 10px 0px;
        }

        .split-mode {
            width: 100%;
        }

        .split-simple-mode-select-label {
            font-size: 10px;
        }
    }

    @media (max-width: 360px) {

        .pdf-to-jpg-quality {
        }
    }

    @media (max-width: 320px) {


        .markup-docuviewer-control div div :last-child .dvUi-resizable {
            width: 50% !important;
        }

        .pdf-to-jpg-quality {
        }

        #signature canvas {
            height: 160px !important;
        }
    }

    .upload-more {
        margin: 0 15px 0 0;

        @media (max-width: 576px) {
            display: inline-block;
            margin: 0 0 15px;
        }
    }
    /* Mobile grid homepage*/
    @media (max-width: 576px) {

        .home-header {
            font-size: 26px;
            line-height: 1.2;
        }

        .tool-item-wrapper a {
            margin: 0;
        }

        .tool-item-wrapper .tool-item {
            text-align: start;
            padding: 0 0 10px;
            margin: 0;
        }

        .tool-item-content,
        .small-icons .tool-item-content {
            width: 100%;
            height: auto;
        }

        .tool-item-content.rounded-icon,
        .small-icons .tool-item-content.rounded-icon {
            border-radius: @border-radius-base;
        }

        .tool-item-icon {
            display: inline-block;
            text-align: center;
            max-width: 80px;
            height: auto;
        }

        .tool-item-description {
            display: inline-block;
        }

        .coming-soon {
            font-size: 12px;
            top: 4px;
            right: -6px;
        }

        .ant-modal-body {
            padding: 30px 10px;
        }

        .ant-modal {
            max-width: 96%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    /* End Mobile grid homepage*/

    .flexbox-item-grow {
        flex: 1;
        /* same as flex: 1 1 auto; */
    }

    .fill-area {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        /* align items in Main Axis */
        align-items: stretch;
        /* align items in Cross Axis */
        align-content: stretch;
        /* Extra space in Cross Axis */
    }

    .fill-area-content {
        /* Needed for when the area gets squished too far and there is content that can't be displayed */
        /*overflow: auto;*/
    }

    .simple-modal-error-wrapper {

        button {
            border-color: inherit;
        }

        span {
            text-transform: none;
        }

        .ant-modal-content {
            border-radius: @border-radius-base;

            .ant-modal-header {
                border-radius: @border-radius-base @border-radius-base 0 0;
            }

            .ant-modal-title {
                font-size: 18px;
            }
        }

        .anticon {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            vertical-align: middle;

            svg {
                height: 100%;
                width: 100%;
            }
        }

        .small-error-content {
            background: rgba(0, 0, 0, 0.1);
            border-radius: @border-radius-base;
            padding: 1em;
            max-height: 290px;
            min-height: 128px;
            overflow: auto;

            @media (max-width: 576px) {
                max-height: 190px;
            }
        }
    }
    /*====================================
========== 17. Tools Colors ==========

    moved to less
======================================*/
    .icon-fill-upload,
    .icon-fill-upload:hover,
    .icon-fill-upload:focus {
        background: rgba(188, 75, 201, 1);
    }

    .icon-fill-download,
    .icon-fill-download:hover,
    .icon-fill-download:focus {
        background: rgba(188, 75, 201, 1);
    }

    .icon-fill-document,
    .icon-fill-document:hover,
    .icon-fill-document:focus {
        background: rgba(188, 75, 201, 1);
    }

    .icon-fill-loading,
    .icon-fill-loading:hover,
    .icon-fill-loading:focus {
        background: rgba(188, 75, 201, 1);
    }

    .coming-soon-icon {
        opacity: 0.5;
    }

    .response-error-wrapper {
        margin: auto;
    }

    .unlock-offer p,
    .unlock-offer-link {
        text-align: center;
    }

    .width_100_per {
        width: 100%;
    }

    .height_100_per {
        height: 100%;
    }

    .document-thumbnail {
        width: 100px;
        height: 100px;
        display: block;
        margin: 16px;
    }

    .not-found-header {
        font-size: 10em;
        line-height: 130px;
        font-weight: bold;
        padding-bottom: 24px;
    }

    .not-found {
        padding-top: 76px;
        text-align: start;
    }

    .not-found-title {
        font-size: 38px;
        line-height: 48px;
        font-weight: bold;
        padding-bottom: 12px;
    }

    .not-found-subtitle {
        padding-bottom: 30px;
    }

    .not-found-action,
    .not-found-action:hover,
    .not-found-action:focus {
        background: rgba(0, 0, 0, 1);
        color: #ffffff;
    }

    .not-found-image img {
        width: 100%;
    }

    .reload {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        border: 1px solid rgba(102,110,132,.2);
        padding: 12px;
        border-radius: @border-radius-base;
        width: 48px;
        height: 48px;

        svg {
            vertical-align: baseline;

            path {
                fill: #1d7c76;
            }
        }
    }

    @media (max-width: 767.98px) {
        .reload {
            right: 15px;
            top: 15px;
        }
    }
    /* styles for '...' */
    .ellipsis-lines {
        overflow: hidden;
        position: relative;
        line-height: 1.2em;
        max-height: 3em;
        margin-right: -1em;
        padding-right: 1em;
        word-break: break-word;
        right: 2px;
    }
    /*====================================
========== 18. Signup Page ==========
======================================*/

    .auth-container-block {
        background-color: #fff;
        border-radius: 0;
        overflow: hidden;
    }

    .auth-tabs a button {
        background-color: transparent;
        color: @primary-color;
        border: none;
    }

    .auth-tabs a:first-child button {
        border-top-left-radius: 7px;
    }

    .auth-tabs a:last-child button {
        border-top-right-radius: 7px;
        border-right: none;
    }

    .auth-tabs a button.active {
        background-color: transparent;
        border-bottom: 1px solid #fff;
    }

    .auth-socials {
        text-align: start;
    }

    .auth-socials button {
        border-radius: @border-radius-base;
    }

    .auth-socials svg.social-icon path {
        fill: #fff;
    }

    .auth-socials .social-icon-label {
        margin-left: 50px;
    }

    @media (max-width: 320px) {

        span.ant-radio + * {
            padding-left: 5px !important;
            margin-bottom: 15px !important;
        }
    }

    .auth-socials-separator {
        background-color: @border-color-base;
    }

    .auth-socials-separator-text-wraper span {
        color: @primary-color;
        background-color: #fff;
    }

    .auth-form {

        .ant-row {
            .ant-col {
                width: 100%;
            }
        }

        .ant-input {
            border: none;
            border-radius: 0 !important;


            &:focus,
            &:hover {
                border: none;
            }
        }

        .has-error {
            color: #ff0000;

            .ant-legacy-form-explain {
                font-size: 14px;
            }
        }

        .ant-form-item {
            margin-bottom: 30px;
        }

        .ant-form-item-with-help {
            margin-bottom: 11px;
        }

        .ant-form-item:last-child {
            margin-bottom: 0;
        }

        .ant-form-item-with-help:last-child {
            margin-bottom: -19px;
        }

        .ant-form-item-with-help .ant-input,
        .ant-form-item-with-help .ant-input:hover,
        .ant-form-item-with-help .ant-input:focus {
            border: 1px solid red;
            color: red;
        }

        .ant-form-item input.ant-input::placeholder,
        .ant-form-item input.ant-input::placeholder {
            visibility: visible;
            opacity: 1;
            transition: visibility 0.2s, opacity 0.2s linear;
        }

        .ant-form-item:hover input.ant-input::placeholder,
        .ant-form-item:focus input.ant-input::placeholder {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.2s, opacity 0.2s linear;
        }

        .ant-input-prefix {
            img {
                width: 17px;
            }
        }

        .ant-form-item-with-help .ant-input-prefix svg {
            fill: red;
        }

        .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            border-radius: @border-radius-base;
        }

        .ant-checkbox-checked .ant-checkbox-inner::after {
            -webkit-transform: rotate(50deg) scale(2) translate(-10%, -45%);
            -ms-transform: rotate(50deg) scale(2) translate(-10%, -45%);
            transform: rotate(50deg) scale(2) translate(-10%, -45%);
            left: 6%;
        }

        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: @primary-color;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: @primary-color;
            border-color: @primary-color;
        }

        .ant-checkbox-checked::after {
            border: none;
        }

        .remember-checkbox {
            color: @primary-color;
            align-items: center;
        }

        .login-forgot-password {
            text-decoration: underline;
            color: @primary-color;
            font-size: 14px;
        }

        .input-corner-label {
            transition: visibility 0.2s, opacity 0.2s linear;
            border-radius: 0 0 @border-radius-base @border-radius-base;
        }

        .ant-form-item-with-help .input-corner-label {
            color: red;
        }

        .ant-form-item:hover .input-corner-label,
        .ant-form-item:focus .input-corner-label {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s, opacity 0.2s linear;
        }
    }


    .auth-action-button-block button {
        background-color: darken(@primary-color, 5%);
        border-radius: @border-radius-base;

        &:disabled {
            cursor: not-allowed;
            color: lighten(@text-color, 25%);
            background-color: rgba(0,0,0,.08);
            box-shadow: none;
        }
    }

    .auth-action-button-block .bottom-colored-border {
        display: block;
    }

    .auth-action-button-block .bottom-colored-border img {
        display: block;
        border-radius: 0 0 @border-radius-base @border-radius-base;
    }

    .profile-dropdown-button {
        font-size: 16px;
        font-weight: 500;

        a {
            color: @primary-color;

            &:focus,
            &:hover {
                color: @primary-color;
            }
        }
    }

    .profile-dropdown-button .dropdown-icon {
        padding-left: 10px;
        vertical-align: 0.125em;
    }

    .profile-dropdown {
        font-size: 16px;
        font-weight: 400;
    }

    .profile-dropdown .dropdown-iteam {
        margin-left: 30px;
        margin-right: 30px;
    }

    .profile-dropdown .dropdown-iteam .disabled {
        cursor: not-allowed;
        margin-left: 30px;
        margin-right: 30px;
        color: #707070;
    }

    .profile-dropdown .icon-dropdown {
        width: 20px !important;
        margin-right: 10px !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        vertical-align: middle !important;

        path {
            fill: @primary-color;
        }
    }

    .locked-error-buttons {
        margin-right: 30px;
    }

    .loading-centred {
        margin: 0 auto;
    }

    .tool-DV-container {
        width: 100%;
    }
    /*===== Action Row =====*/
    .tools-actions-row {
        background: #fff;
        border-top: 1px dashed rgba(102,110,132,.8);
        border-bottom: 1px dashed rgba(102,110,132,.8);
        margin: 0;
    }

    .tools-rotate-row {
        margin: 0;
    }

    .fixed-action-row {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
        border-bottom: none !important;
        border-radius: 0 0 @border-radius-base @border-radius-base;
        z-index: 100;
    }

    @media (max-width: 1200px) {
        .fixed-action-row {
            max-width: 924px;
        }
    }

    @media (max-width: 992px) {
        .fixed-action-row {
            max-width: 684px;
        }
    }

    @media (max-width: 764px) {
        .fixed-action-row {
            max-width: 504px;
        }
    }

    @media (max-width: 576px) {
        .fixed-action-row {
            max-width: 100%;
        }
    }
    /*==========================================
========== 19. Logged user header ==========
============================================*/

    .auth-buttons.profile-dropdown-button a.logged-user-text {
        line-height: 40px;
        display: initial;
        background-color: initial !important;
        padding: initial;
        border-radius: initial;
    }

    ul.logged-in-hamburger-menu li {
        margin-bottom: 0;
    }

    @media (max-width: 992px) {
        .picker-dialog {
            top: 75px !important;
            height: 580px !important;
            width: 99% !important;
        }

        .picker-dialog-content {
            height: 565px !important;
            width: 100% !important;
        }
    }

    @media (max-width: 768px) {

        .logged-user-image {
            width: 32px;
            height: 32px;
        }

        .profile-dropdown-button .dropdown-icon {
            padding-left: 2px;
            vertical-align: -0.025em;
        }
    }

    @media (max-width: 320px) {

        .picker-dialog {
            top: 50px !important;
            height: 480px !important;
            width: 99% !important;
        }

        .picker-dialog-content {
            height: 465px !important;
            width: 100% !important;
        }
    }

    #dv-viewer-container {
        height: 780px !important;

        @media only screen and (max-width: 992px) {
            height: 500px !important;
        }

        @media only screen and (max-width: 480px) {
            height: 450px !important;
        }
    }
    /*============================================
========== 20. Document Viewer Page ==========
==============================================*/
    .document-viewer-loading-wrapper {
        display: table;
        text-align: center;
    }

    .document-viewer-loading-wrapper .document-viewer-loader {
        width: 200px;
        top: 50%;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        margin: -100px auto 0;
    }

    .document-viewer-loading-wrapper .document-viewer-loader img {
        width: 150px;
    }
    /*====== Custom angle =====*/
    .apply-cutom-angle {
        padding: 0;
    }

    .apply-cutom-angle input {
        border: none;
        width: 50%;
        height: 100%;
        text-align: center;
    }

    .apply-cutom-angle:hover,
    .apply-cutom-angle:focus {
    }

    .apply-cutom-angle-input {
        border-radius: @border-radius-base 0 0 @border-radius-base;
    }

    .apply-cutom-angle-input.invalid {
        color: red;
    }

    .apply-custom-angle-button {
        border-radius: 0 @border-radius-base @border-radius-base 0;
        background: @primary-color;
        color: #fff;
        transition: all 0.3s;
    }

    .apply-custom-angle-button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .layout-thumbnails {
        padding: 0;
    }

    .docuvieware-layout {
        position: relative;
        overflow-y: hidden;
        display: flex;
        z-index: 1;
    }

    @media (max-width: 768px) {

        .avepdf-toolbar .shape-container {
            margin: auto;
        }

        .text-align-center .remove-file-title {
            padding-bottom: 0;
        }
    }

    .shape-container {
        margin: auto 15px;
    }

    .pdf-edit-selected-annotation-toolbar .shape-toolbar-button {
        width: 2rem;
        height: 2rem;
        margin: 0;
        background: #ffffff;
    }

    .pdf-edit-selected-annotation-toolbar .shape-toolbar-button span {
        width: 1.5rem;
        top: -12px;
        position: relative;
        left: -12px;
        opacity: 0.3;
    }

    .additional-options-container {
        display: flex;
        flex-direction: row;
        margin-left: 16rem;
        align-items: center;
    }

    .ant-select-dropdown {
    }

    .ant-dropdown .ant-dropdown-menu .shape-menu-item:hover {
        background-color: #d3fbff;
    }

    .ant-dropdown-menu-item-divider {
        margin: 0;
    }

    .shape-button-menu-item {
        border-radius: 0;
        border: 0;
    }

    .ant-dropdown .ant-dropdown-menu .shape-menu-item .shape-button-menu-item:hover {
        background-color: #d3fbff;
    }

    .move-arrow-button span img {
        left: 1px;
        top: 1px;
    }


    .margin-0 {
        margin: 0 !important;
    }

    .remove-file-title {
        padding-top: 60px;
        padding-bottom: 0;
    }

    .padding-0 {
        padding: 0 !important;
    }

    .overflow-visible {
        overflow: visible;
    }

    .file-actions-layout {
        width: 100%;
        text-align: center;
    }
    // Shape Button
    .shape-button {
        padding: 6px;

        svg {
            width: 23px;
            height: 23px;
            position: relative;
        }

        &:focus {
            background-color: inherit !important;
            border-color: #d9d9d9 !important;
        }

        @media (max-width: 767.98px) {
            padding: 10px;
        }
    }

    .avepdf-toolbar {

        .shape-button {
            span:not(.async-svg-container) {
                left: -12px;
                width: 2rem;
            }

            svg {
                fill: @primary-color;
            }
        }
    }

    .pdf-edit-selected-annotation-toolbar .floating-toolbar-btn svg path {
        fill: inherit;
    }

    .pdf-edit-selected-annotation-toolbar {
        z-index: 170000000;
        position: absolute;
        left: 40%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .pdf-edit-selected-annotation-toolbar .color-picker-button {
        display: flex;
    }

    .pdf-edit-selected-annotation-toolbar .shape-toolbar-button .color-picker-display {
        width: 70%;
        height: 70%;
        margin: auto;
    }

    .edit-docuviewer-control {
    }

    .border-style-dropdown-item {
        background-position: 1px 10px;
        background-repeat: repeat-x;
        padding: 0;
        height: 26px;
        width: 80px
    }

    [class*="border-width-"] {
        background-position: 1px 3px;
    }

    .popover {
        position: absolute;
        z-index: 180000000;
    }

    .cover {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .color-picker-button {
        width: 2.5rem;
        height: 2.5rem;
        margin: auto 2px;
        border-radius: @border-radius-base;
        border: 1px solid;
    }

    .color-picker-display {
        width: 100%;
        height: 100%;
        min-height: 26px;
        border-radius: @border-radius-base;
    }

    .color-picker-cross {
        width: 1rem;
        top: -2px;
        left: -6px;
    }

    .color-picker-cross img {
        height: 2rem;
    }

    .pdf-edit-selected-annotation-toolbar .shape-toolbar-button .color-picker-cross {
        width: 1rem;
        top: 0.1rem;
        left: 0.2rem;
        opacity: 1;
    }

    .pdf-edit-selected-annotation-toolbar .shape-toolbar-button .color-picker-cross img {
        height: 1.5rem;
    }

    .color-picker-wrapper {
        padding: 10px 10px 0;
        box-sizing: initial;
        background: #fff;
        border-radius: @border-radius-base;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.15);
    }

    .color-picker-saturation-container {
        width: 100%;
        padding-bottom: 75%;
        position: relative;
        overflow: hidden;
    }

    .color-picker-saturation {
    }

    .color-picker-controls {
        display: flex;
    }

    .color-picker-sliders {
        padding: 4px 0;
        flex: 1;
    }

    .color-picker-hue-container {
        position: relative;
        height: 10px;
        overflow: hidden;
    }

    .color-picker-hue {
    }

    .color-picker-alpha-container {
        position: relative;
        height: 10px;
        margin-top: 4px;
        overflow: hidden;
    }

    .color-picker-alpha {
    }

    .color-picker-color-container {
        width: 24px;
        height: 24px;
        position: relative;
        margin-top: 4px;
        margin-left: 4px;
        border-radius: 3px;
    }

    .color-picker-active-color {
        border-radius: 2px;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15), inset 0 0 4px rgba(0, 0, 0, 0.25);
    }

    .color-picker-colors-container {
        margin: 0 -10px;
        padding: 10px 0 0 10px;
        border-top: 1px solid #eee;
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .color-none-button {
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: @border-radius-base;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .color-picker-swatch-wrap {
        width: 16px;
        height: 16px;
        margin: 0 10px 10px 0;
    }

    .color-picker-swatch {
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
    }

    .color-picker-active-swatch {
        outline: 2px solid #6ed7ff;
    }

    .color-none-svg {
        position: relative;
        top: -7px;
        right: 7px;
        width: 16px;
        height: 16px;
    }

    .zoom-wrapper {
        margin-left: auto !important;
    }

    .trash-image {
        height: 1.5rem;
    }

    .shape-dropdown-active {
        background-color: #beebff;
    }

    .resize-controls {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        margin: auto;
        justify-content: center;
    }

    .resize-button {
        margin: auto 10px;
    }

    .resize-input-height {
        margin-left: 0;
    }

    .resize-homometric-image {
        position: relative;
        opacity: 1;
        height: 20px;
        bottom: 6px;
        right: 5px;
    }

    .border-style-solid {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' d='M0 4 l32 0' /></g></svg>");
    }

    .border-style-dash {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' stroke-dasharray='8,4' d='M0 4 l32 0' /></g></svg>");
    }

    .border-style-dot {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' stroke-dasharray='2,3' d='M0 4 l32 0' /></g></svg>");
    }

    .border-style-dashdot {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' stroke-dasharray='6,2,2,2' d='M0 4 l32 0' /></g></svg>");
    }

    .border-style-dashdotdot {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='6' width='32'><g fill='none' stroke='black'><path stroke-width='2' stroke-dasharray='6,2,2,2,2,4' d='M0 4 l32 0' /></g></svg>");
    }

    .border-width-extrathin {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='2' d='M0 10 l32 0' /></g></svg>");
    }

    .border-width-thin {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='4' d='M0 10 l32 0' /></g></svg>");
    }

    .border-width-normal {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='6' d='M0 10 l32 0' /></g></svg>");
    }

    .border-width-strong {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='8' d='M0 10 l32 0' /></g></svg>");
    }

    .border-width-verystrong {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='15' width='32'><g fill='none' stroke='black'><path stroke-width='10' d='M0 10 l32 0' /></g></svg>");
    }

    .text-style-regular {
        background-image: url("./assets/images/regular_text.png");
    }

    .text-style-bold {
        background-image: url("./assets/images/bold-text.png");
    }

    .text-style-italic {
        background-image: url("./assets/images/italic-text.png");
    }

    .ant-modal-wrap .text-annotation-modal {
        max-width: calc(100vw - 16px);
    }

    .text-annotation-input {
        width: 30%;
    }

    .text-annotation-button {
        margin: 10px auto;
    }

    .text-annotation-style-item {
        display: flex;
        align-items: center;
        padding-left: 35px;
    }

    .col-12 .text-annotation-textarea {
        resize: none;
        height: 150px;
        width: 100%;
    }

    .align-text-center .text-annotation-font-style {
        width: 130px;
    }

    .text-annotation-menu {
        max-height: 250px;
        overflow-y: scroll;
    }

    .text-annotation-font-name-label {
        position: relative;
        width: 180px;
        height: 2.5rem;
        padding: 5px;
        word-wrap: normal;
        overflow: hidden;
        border-radius: @border-radius-base;
        display: flex;
        justify-content: flex-start;
    }

    .text-annotation-font-name-label::after {
        text-align: center;
        content: "";
        position: absolute;
        top: 15px;
        font-size: 14px;
        padding: 3px;
        border: solid;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        right: 20px;
    }

    .text-annotation-font-name-label span {
        display: block;
        margin: auto;
        white-space: nowrap;
    }

    .flatten-pages-dropdown {
        position: relative;
        background: #ffffff;
        width: 40px;
        padding: 5px;
        border-radius: @border-radius-base;
        border: 1px solid #d9d9d9;
        display: flex;
    }

    .text-annotation-input-number .ant-input-number-handler-wrap {
        opacity: 1;
    }

    .pdf-edit-text-annotation-toolbar .text-color-picker {
        border-radius: 0;
        display: flex;
    }

    .pdf-edit-text-annotation-toolbar .text-color-picker .color-picker-display {
        width: 70%;
        height: 70%;
        margin: auto;
    }

    .pdf-edit-selected-annotation-toolbar .shape-button .svg-small-icon {
        width: 1.4rem;
    }

    .toolbar-color-picker {
        display: flex;
        justify-content: center;
        height: 2.5rem;
        padding: 0 5px;
        border-radius: @border-radius-base;
        border: 1px solid rgb(217, 217, 217);
    }

    .toolbar-color-picker .color-picker-button {
        width: 1.6rem;
        height: 1.6rem;
    }

    .toolbar-color-picker .toolbar-color-picker-title {
        font-size: 13px;
        margin: auto;
        padding: 0 5px;
    }

    .mobile-container {
        display: none;
    }

    .button-container {
        display: flex;
    }

    @media (max-width: 991px) {
        .button-container {
            width: 60%;
            flex-wrap: wrap;
        }

        .button-container .shape-button {
            border-radius: @border-radius-base;
            margin: 0.15rem;
        }
    }

    .shape-selection-toolbar-popover {
        width: auto;
        padding-top: 4px;
    }

    .shape-selection-toolbar-popover .ant-popover-inner {
        background-color: #fafbfd;
        border-radius: @border-radius-base;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .shape-selection-toolbar-popover .ant-popover-inner-content {
        padding: 0px;
        border-radius: @border-radius-base;
        border: 1px solid @border-color-base;
    }

    .ant-popover-arrow-content {
        background-color: transparent;
        box-shadow: none !important;
    }

    .ant-popover-arrow-content:before {
        background-color: transparent;
    }

    .ant-popover-inner-content .shape-button {
        margin: 3px 3px;
    }

    .shape-selection-toolbar-popover .ant-popover-content .ant-popover-arrow {
        top: -7px;
        background: #fafbfd;
        border-left: 1px solid @border-color-base;
        border-top: 1px solid @border-color-base;
        transform: translateY(0px) rotate(45deg);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 14px;
        height: 14px;
    }

    .did-you-know-main-tile {
    }

    .text-align-right {
        text-align: end;
    }

    .text-align-left {
        text-align: start;
    }

    .esign-signature-toolbar {
        padding-top: 20px;
        text-align: start !important;
    }

    .margin-0 {
        margin: 0px !important;
    }

    .popupLink {
        color: #42a5f5;
    }

    .popup-file-formats {
        padding-top: 5%;
        padding-bottom: 5%;
        /* border: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0; */
    }

    .popup-format-column-on {
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        border-top: 1px solid #e8e8e8;
        border-radius: @border-radius-base @border-radius-base 0 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .popup-format-column-off {
        border-bottom: 1px solid #e8e8e8;
        border-radius: @border-radius-base @border-radius-base 0 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .popup-format-block-left {
        border-left: 1px solid #e8e8e8;
        padding-top: 30px;
        padding-bottom: 0px !important;
    }

    .popup-format-block-left-last {
        border-left: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        border-radius: 0 0 @border-radius-base @border-radius-base;
        padding-top: 30px;
        padding-bottom: 0px !important;
    }

    .popup-format-block-right {
        border-right: 1px solid #e8e8e8;
        padding-top: 30px;
        padding-bottom: 0px !important;
    }

    .popup-format-block-right-last {
        border-right: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        border-radius: 0 0 @border-radius-base @border-radius-base;
        padding-top: 30px;
        padding-bottom: 0px !important;
    }

    .no-padding {
        padding: 0px;
    }

    .popup-modal .ant-modal-content .ant-modal-close .anticon {
    }

    .popup-modal .ant-modal-content .ant-modal-footer {
        padding: 0px;
        border-top: none;
    }

    .popup-modal {
    }

    .popup-text {
    }

    .padding-right-0 {
        padding-right: 0px !important;
    }

    .side-arrow {
        padding-right: 8px;
    }

    .esign-mode-buttons {
        text-align: start;
        margin: 0 !important;
    }

    .text-annotation-collapse .ant-collapse-item {
        border-bottom: 0;
    }

    .align-items-end {
        align-items: flex-end;
    }

    .signature-draw-wrapper {
        max-width: 100%;
        margin: 0 auto;
        top: 12px;
    }

    .draw-signature-buttons {
        justify-content: flex-start !important;
    }

    .draw-signature-buttons div .action-button {
        padding-right: 20px;
        padding-left: 20px;
    }

    .display-none {
        display: none;
    }

    .esign-box {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .file-picker-button div {
        width: 100%;
        display: flex;
    }

    .file-picker-button {
        .ant-btn {
            background: inherit;
            width: 100%;
            border: none;
            border-radius: 0;
            line-height: 2;

            span {
                font-size: 12px;
                color: @primary-color;

                svg {
                    path {
                        fill: @primary-color;
                    }
                }
            }

            &:hover,
            &:focus {
                background-color: @primary-color;

                span {
                    color: #fff;
                }


                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        &:first-child:not(:last-child) {
            .ant-btn {
                border-radius: @border-radius-base @border-radius-base 0 0;
            }
        }

        &:last-child:not(:first-child) {
            .ant-btn {
                border-radius: 0 0 @border-radius-base @border-radius-base;
            }
        }
    }



    .file-picker-button-title {
        margin: auto 0 auto 8px;
    }

    .file-upload-popover {
        display: flex;
        flex-direction: column;

        button {
            text-align: start;
        }
    }

    .shape-toolbar-image-wrapper {
        display: flex;
        flex-direction: row;
        height: 2rem;
        width: max-content;
        font-size: 14px;
        background: #FFFFFF;
        border-radius: @border-radius-base;
        border: 1px solid rgb(217, 217, 217);
    }

    .shape-toolbar-image-opacity {
        margin: 9px 15px;
        margin-left: 5px;
        width: 50px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .shape-toolbar-image-wrapper span {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .shape-toolbar-image-opacity .ant-slider-rail {
        height: 5px;
    }

    .shape-toolbar-image-opacity .ant-slider-handle {
        border: 0;
        width: 10px;
        height: 10px;
        margin-top: -2.5px;
    }

    .shape-toolbar-image-opacity-icon {
        margin: 23px 3px;
        margin-right: 20px;
        width: 50px;
    }

    .shape-toolbar-image-opacity-icon .ant-slider-step {
        height: 8px;
    }

    .shape-toolbar-image-opacity-icon .ant-slider-rail {
        height: 8px;
    }

    .shape-toolbar-image-opacity-icon .ant-slider-handle {
        border: 0;
        margin-top: -2.5px;
    }

    .certificate-modal div div .ant-modal-footer {
        text-align: center;
    }

    .slider-value {
        width: 40px;
        display: inline-block;
        text-align: center;
        margin-left: 15px;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
    }

    .pdf-to-jpg-quality .ant-slider-rail {
        margin-left: 0px;
        width: calc(100% + 6px);
    }

    .pdf-to-jpg-quality .ant-slider-handle {
        border: 0;
        margin-top: -3px;
    }

    .cleanup-button {
        width: max-content;
    }

    .cleanup-button span {
        opacity: 1;
    }

    .cleanup-button .cleanup-filter-title {
        width: max-content;
    }

    .cleanup-save-button {
        display: block;
        margin: auto;
    }

    @media (max-width: 991px) {
        .cleanup-button {
            width: 100%;
            padding: 10px !important;
        }
    }

    .insert-page-input input {
        padding-left: 20px !important;
    }

    .keep-proportions label span {
        padding-right: 5px;
    }

    .opacity-icon svg {
        fill: #1f1f1f;
    }

    .shape-container {
        margin: auto 10px;
    }

    .keep-prop .ant-checkbox .ant-checkbox-inner::after {
        border-color: #0d7057;
    }

    .keep-prop .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #0d7057;
    }

    .keep-prop .ant-checkbox .ant-checkbox-inner {
        border-color: #d9d9d9;
        background-color: #8c7a7f00;
    }

    .keep-prop .ant-checkbox-checked .ant-checkbox-inner {
        border-color: #d9d9d9;
        background-color: #8c7a7f00;
    }

    .opacity-old {
        margin: auto 12px !important;
    }

    .opacity-icon {
        padding: 8px;
        height: 44px;
        width: 44px;
        display: inline-block;
    }

    .opacity-icon svg {
        width: 100%;
        height: auto;
    }

    .second-slider {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    .cookie-warning-modal {
        overflow: hidden;
        top: 50px;
    }

    .cookie-warning-modal .ant-modal-content .ant-modal-header {
        border-bottom: 0px;
    }

    .cookie-warning-modal .ant-modal-content .ant-modal-footer {
        border-top: 0px;
    }

    .browser-type-icon {
        padding-right: 25px;
        display: initial;
    }

    .warning-title {
        font-size: 24px;
        padding-left: 10px;
        max-width: 75%;
        justify-content: center;
        font-weight: bold;
    }

    .cookie-warning-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
        border: 1px solid black;
        border-radius: @border-radius-base;
        color: white;
        background-color: black;
        width: 26px;
        height: 22px;
        line-height: 0.9;
        color: white;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 4px;
    }

    .cookie-warning-modal .ant-modal-content .ant-modal-close .ant-modal-close-x i img {
        width: 18px;
        height: 18px;
        padding-left: 1px;
    }

    .ant-modal-wrap {
        overflow: auto;
    }

    .keep-aspect-button-on img {
        margin: 3px;
        width: 20px;
        fill: #0d7057;
    }

    .keep-aspect-button-off img {
        margin: 3px;
        width: 20px;
        fill: #b1b1b1;
    }

    .keep-aspect-ratio-button {
        width: 42px;
        height: 42px;
        border-radius: @border-radius-base;
        padding: 0px;
    }


    .shape-button-image-toolbar {
        background-color: rgba(148, 191, 218, 0.03);
        width: 2.5rem;
        height: 2.5rem;
        border-radius: @border-radius-base;
    }


    .shape-button-image-toolbar span img {
        position: relative;
        width: 100%;
    }

    .shape-button-image-toolbar-svg {
        position: relative;
        width: 3rem;
        height: 2rem;
        top: -9px;
        left: -9px;
    }

    .pdf-edit-selected-annotation-toolbar .shape-annotation-toolbar-button {
        width: 2rem;
        height: 2rem;
        margin: 2px;
        background: #fafbfd;
    }

    .shape-annotation-toolbar-button {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
    }

    .trash-button img {
        width: 20px;
        fill: #0d7057;
    }

    .keep-aspect-ratio-button .keep-aspect-button-on {
        width: 38px;
    }

    .keep-aspect-ratio-button .keep-aspect-button-off {
        width: 38px;
    }

    .keep-proportions .shape-toolbar-image-value {
        font-size: 18px;
        color: #1f1f1f;
    }

    .aspect-ratio-tooltip {
    }
    /* Floating Toolbar */
    .floating-toolbar {
        width: auto !important;
        height: auto !important;
    }

    .floating-opacity-slider {
        width: 154px;
        margin: 15px 5px 16px;
    }

    .floating-bar-input {
        padding: 4px 15px;
        width: 94px;
    }

    .floating-bar-input .ant-input {
        height: 28px;
    }

    .floating-toolbar-btn-wrapper {
        width: 46px !important;
        height: 46px !important;
        padding: 4px;
    }

    .floating-toolbar-btn-content {
        width: 36px;
        height: 36px;
        padding: 0 !important;

        .ant-btn {
            svg {
                path {
                    fill: @primary-color;
                }
            }
        }
    }

    .floating-toolbar-btn {
        width: 100%;
        height: 100%;
        padding: 4px;
    }

    .floating-toolbar-btn img {
        margin: 0;
        width: 100%;
        height: auto;
    }

    @media (max-width: 767.98px) {

        .floating-opacity-slider {
            width: 154px;
            margin: 15px 6px;
        }

        .floating-bar-input {
            padding: 3px 15px;
            width: 94px;
        }

        .opacity-icon {
            padding: 8px;
            height: 42px;
            width: 42px;
            display: inline-block;
        }

        .floating-toolbar-btn-wrapper {
            width: 44px !important;
            height: 44px !important;
            padding: 4px;
        }

        .floating-toolbar-btn-content {
            width: 34px;
            height: 34px;
        }

        .ant-radio-button-wrapper:first-child {
            border-radius: @border-radius-base @border-radius-base 0 0;
        }

        .ant-radio-button-wrapper {
            @media (max-width: 768px) {
                border: 1px solid @border-color-base;
                margin-top: -1px !important;
                border-radius: 0;
            }
        }

        .ant-radio-button-wrapper:last-child {
            border-radius: 0 0 @border-radius-base @border-radius-base;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }
    }

    @media (max-width: 575.98px) {
        .floating-opacity-slider {
            width: 68px;
        }
    }

    .edit-toolbar-picker {
        width: 16px;
        height: 16px;
    }

    .page-selection-disabled {
        opacity: 0.4;
    }

    .page-selection-enabled {
        opacity: 1;
    }

    .flatten-select {
        height: 32px;
        min-width: 70px;
    }

    .flatten-select div {
        border-radius: @border-radius-base;
        height: 32px;
        background-color: transparent;
    }

    .flatten-page-option-list {
        max-height: 240px;
        overflow: auto;
    }

    .flatten-page-option-list .ant-dropdown-menu-item {
        padding: 4px 10px !important;
    }

    .protect-pdf-select-mode-title {
        font-size: 26px;
        font-weight: bold;
        color: @primary-color;
    }

    .protect-pdf-mode-wrapper {
        width: 250px;
        padding: 10px;
        border: 1px solid rgba(102,110,132,.4);
        border-radius: @border-radius-base;
        cursor: pointer;
    }

    .protect-pdf-mode-wrapper svg {
        margin: 20px;
        width: 60px;
    }

    .protect-pdf-password-field {
        padding-bottom: 30px;
    }

    .disabled-block {
        opacity: 0.4;
    }


    .ant-radio-button-wrapper-checked {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .ant-radio-button-wrapper-checked:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /* Ant Default Tooltips */
    .ant-tooltip {
        padding: 5px 10px;
        font-size: 13px;

        .ant-tooltip-inner {
            background-color: @primary-color;
        }
    }

    .ant-tooltip-placement-left .ant-tooltip-arrow,
    .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
    .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
        border-left-color: @primary-color;
        right: 6px;
    }

    .ant-tooltip-placement-bottom .ant-tooltip-arrow,
    .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
    .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
        top: 0px;
    }

    .ant-tooltip-placement-top .ant-tooltip-arrow,
    .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
    .ant-tooltip-placement-topRight .ant-tooltip-arrow {
        bottom: 0px;
        border-top-color: @primary-color;
    }
    /* Loading Bar */
    .ave-loading-bar {
        height: 20px;
        width: 100%;
        border-radius: 0 0 @border-radius-base @border-radius-base;
        margin-bottom: 30px;
        overflow: hidden;
        padding: 0 8px;
    }

    .ave-loading {
        height: 100%;
        transition: width .5s linear;
    }

    .ave-loading-text p {
        font-size: 25px;
        line-height: 1.5;
    }

    .download-action-button {
    }

    .download-action-button[disabled],
    .download-action-button[disabled]:hover {
        color: grey;
    }

    .download-action-button[disabled],
    .download-action-button[disabled]:hover {
        color: grey;
    }

    .download-action-button[disabled] .download-icon svg path {
        fill: grey;
    }

    .download-action-button[disabled] img,
    .download-action-button[disabled]:hover img {
        opacity: .5;
    }
    /* Share Overlay */
    .share-overlay {
        position: absolute;
        height: 182%;
        width: 104%;
        background: rgba(15, 15, 15, .95);
        z-index: 999999999;
        top: -68px;
        left: -2%;
    }

    .share-overlay-content {
        top: 0;
        position: relative;
        color: #fff;
    }

    .share-overlay-content .share-icon-bar {
        position: relative;
        width: max-content;
        top: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        background: @primary-color;
        border-radius: @border-radius-base;
        filter: invert(100%);
    }


    .share-overlay-content .share-icon-bar ul li {
        display: inline-block;
        width: 64px;
        text-align: center;
        color: white;
        font-size: 18px;
        background: @primary-color;
        cursor: pointer;
    }

    .share-overlay-content .share-icon-bar ul li:hover {
        background-color: #fff;
        color: @primary-color;
    }

    .share-overlay-content .share-icon-bar ul li:first-child {
        border-radius: @border-radius-base 0 0 @border-radius-base;
    }

    .share-overlay-content .share-icon-bar ul li:last-child {
        border-radius: 0 @border-radius-base @border-radius-base 0;
    }

    @media (max-width: 991.98px) {

        .share-overlay {
            position: absolute;
            height: 182%;
            width: 106%;
            background: rgba(0, 0, 0, .80);
            z-index: 999999999;
            top: -68px;
            left: -3%;
        }

        .share-overlay .ave-loading-bar {
            padding: 0;
        }
    }

    @media (max-width: 767.98px) {
        .share-overlay {
            position: absolute;
            height: 200%;
            width: 106%;
            background: rgba(0, 0, 0, .80);
            z-index: 999999999;
            top: -57px;
            left: -3%;
        }

        .ave-loading-text p {
            font-size: 16px;
            line-height: 1.5;
            padding: 0 5px;
        }
    }


    @media (max-width: 576px) {

        .share-overlay {
            position: absolute;
            height: 128%;
            width: 106%;
            background: rgba(0, 0, 0, .80);
            z-index: 999999999;
            top: -57px;
            left: -3%;
        }

        .share-overlay-content .share-icon-bar {
            display: block;
        }
    }
    /*=================================
========== 12. Utilities ==========
===================================*/
    /*========== White Space ==========*/
    .white-space {
        display: block;
        width: 100%;
    }

    .white-space.space-xmini {
        padding: 3px 0;
    }

    .white-space.space-mini {
        padding: 5px 0;
    }

    .white-space.space-xsmall {
        padding: 10px 0;
    }

    .white-space.space-small {
        padding: 15px 0;
    }

    .white-space.space-medium {
        padding: 30px 0;
    }

    .white-space.space-big {
        padding: 60px 0;
    }
    /*========== Icons ==========*/
    .fa {
        font-family: "FontAwesome" !important;
    }

    .icon {
        display: inline-block;
        line-height: 1;
        font-size: inherit;
        vertical-align: middle;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0);
    }

    .icon-lg {
        font-size: 1.33333333em;
        line-height: 1em;
        vertical-align: -15%;
    }

    .icon-2x {
        font-size: 2em !important;
    }

    .icon-3x {
        font-size: 3em !important;
    }

    .icon-4x {
        font-size: 4em !important;
    }

    .icon-5x {
        font-size: 5em !important;
    }
    /* icon position */
    .iconright {
        margin-left: 10px;
    }

    .iconleft {
        margin-right: 10px;
    }
    /*========== Icon List Styles ==========*/
    .fa-ul {
        margin-left: 2em;
    }

    .fa-ul > li {
        line-height: 2em;
    }

    .fa-ul .fa-li {
        height: 2em;
        left: -2em;
        line-height: 1.9;
        position: absolute;
        text-align: center;
        top: 0;
        width: 2em;
    }

    .icon-ul {
        list-style-type: none;
        margin-left: 2em;
        padding-left: 0;
    }

    .icon-ul > li {
        position: relative;
        line-height: 2em;
    }

    .icon-ul .icon-li {
        left: -2em;
        position: absolute;
        text-align: center;
        width: 2em;
        height: 2em;
        line-height: 2.3;
    }

    .fa-ul.icon-border,
    .icon-ul.icon-border,
    .fa-ul.icon-bg-box,
    .icon-ul.icon-bg-box {
        margin-left: 2.5em;
    }

    .icon-border li,
    .icon-bg-box li {
        margin-bottom: 10px;
    }

    .icon-border > li > .icon-li,
    .icon-border > li > .fa-li {
        border: 1px solid;
        border-radius: @border-radius-base;
        left: -2.5em;
    }

    .icon-border > li > a > .icon-li,
    .icon-border > li > a > .fa-li {
        border: 1px solid;
        border-radius: @border-radius-base;
        left: -2.5em;
    }

    .icon-bg-box > li > .icon-li,
    .icon-bg-box > li > .fa-li {
        background: #f2f2f2;
        left: -2.5em;
        border-radius: @border-radius-base;
    }

    .icon-bg-box > li > a > .icon-li,
    .icon-bg-box > li > a > .fa-li {
        background: #f2f2f2;
        left: -2.5em;
        border-radius: @border-radius-base;
    }

    .icon-radius > li > .icon-li,
    .icon-radius > li > .fa-li {
        border-radius: 50%;
    }

    .icon-radius > li > a > .icon-li,
    .icon-radius > li > a > .fa-li {
        border-radius: 50%;
    }
    /* Icon List */
    .icon-list {
        margin-left: 0 !important;
    }

    .icon-list li {
        margin: 0;
    }

    .icon-list > li > a > .icon-li,
    .icon-list > li > a > .fa-li {
        display: block;
        position: relative;
        left: 0;
    }

    .footer-container .icon-list > li > a,
    .footer-container .icon-list > li > a,
    .footer-container .icon-list > li > a,
    .footer-container .icon-list > li > a {
        color: #ffffff;
    }
    /* Separator List */
    .separator-list {
    }

    .separator-list > li {
        border-left: 1px solid;
    }

    .separator-list > li:first-child {
        border-left: none;
        padding-left: 0 !important;
    }
    /* List Medium */
    .list-medium > li {
        font-size: 1.2em;
        margin-bottom: 15px;
    }

    .fa-ul.list-medium,
    .icon-ul.list-medium {
        margin-left: 3em;
    }
    /* List Big */
    .list-big > li {
        font-size: 1.4em;
        margin-bottom: 15px;
    }

    .fa-ul.list-big,
    .icon-ul.list-big {
        margin-left: 3.5em;
    }
    /* Media List */
    .media-heading {
        text-transform: none;
    }
    /* Rating List */
    .rating-stars {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            margin-left: 5px;
            color: #fadb14;

            &:first-child {
                margin-left: 0;
            }
        }
    }
    /* List 3 columns */
    .list-3-col {
        column-count: 3;

        @media (max-width: 767.98px) {
            column-count: 2;
        }
    }
    /* List 2 columns */
    .list-2-col {
        column-count: 2;
    }
    /*========== Icon List Styles ==========*/
    .icon-error-color {
        color: @error-color;
    }

    .icon-warning-color {
        color: @warning-color;
    }

    .icon-success-color {
        color: @success-color;
    }
    /*===============================
========== Premium ==========
===============================*/
    .plan-card {
        background-color: rgba(234,234,250,.3) !important;

        &:hover {
            box-shadow: 0px 40px 60px -20px rgba(102, 110, 132, 0.15);
        }

        .card-header {
            padding: 1.25rem;
            border: none;
            min-height: 200px;
            background-color: transparent;
            padding-bottom: 0;
        }

        .card-footer {
            padding: 0
        }

        .card-title {
            position: relative;
        }

        &.premium-card {
            border-color: #1D7C76;

            .card-header {
                border-color: #1D7C76;
            }

            .plan-features {

                li {
                    border-color: #1D7C76;
                }
            }

            .card-footer {
                ul {
                    .plan-icon-footer {
                        svg {
                            path {
                                fill: @text-color;
                            }
                        }
                    }
                }
            }
        }
    }

    .plan-pricing {
        margin: 0;
    }

    .plan-btn-wrapper {
        min-height: 70px;
    }

    .plan-value {
        font-size: 2em;
    }

    .plan-features-title {
        font-size: 1.4em;
        font-weight: 500;
    }

    .plan-name {
        font-size: 1.6em;
        border-bottom: 1px solid rgba(102, 110, 132, 0.2);
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .plan-features {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 5px 0;
            padding: 10px 0;

            .plan-list-icon {
                display: inline-block;
                width: 18px;
                height: 18px;
                margin-right: 5px;

                svg {
                    path {
                        fill: @text-color;
                    }
                }
            }
        }
    }

    .plan-users {
        font-size: 16px;
        font-weight: 500;
        position: absolute;
        right: 0;
        color: @text-color;


        .plan-users-icon {
            width: 20px;
            display: inline-block;
            margin-right: 5px;

            path {
                fill: @text-color;
            }
        }
    }

    .secure-data-icon {
        display: inline-block;
        margin: 5px 10px;
        height: 32px;

        svg {
            path {
                fill: @text-color;
            }
        }
    }

    .premium-modal-error {
        .ant-modal-body {
            padding: 50px 30px;
        }

        .ant-modal-close {
            background: none;

            .anticon {
                width: 24px;
                height: 24px;
            }
        }
    }
    /* Premium banner notification*/

    .premium-banner {
        background: linear-gradient(90deg,#f0f3f9 26.7%,#f6f9fd 73.42%);
        text-align: center;

        .ant-alert {
            max-width: 1170px;
            margin: 0 auto;
            background-color: transparent;
            justify-content: center;
            color: @text-color;

            .ant-alert-content {
                flex: initial;
                margin: 0 1.2rem;

                .ant-alert-message {
                    color: @text-color;
                }
            }

            .ant-alert-icon {
                color: rgba(31,132,125,1);
                margin-right: 0;
            }

            .ant-alert-close-icon {
                margin-left: 0;
                padding: 4px;
                border-radius: @border-radius-base;

                &:hover {
                    background-color: #e5e8ed;
                }

                .anticon-close {
                    color: @text-color;
                }
            }
        }
    }
    /* Empty placeholder */
    .placeholder-wrapper {

        &.price-placeholder {
            width: 100px;
            min-height: 46.6667px !important;

            .placeholder-content {
                min-height: 46.6667px !important;
                border-radius: 2px;
            }

            &.placeholder-wide {
                width: 100%;
            }
        }
    }

    .placeholder-content {
        height: auto;
        overflow: hidden;
        background: #000;
        position: relative;
        // Animation
        animation-duration: 1.7s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: placeholderAnimate;
        background: #f6f7f8; // Fallback
        background: linear-gradient(to right, #e9e9ef 2%, #f9f9fe 18%, #e9e9ef 33%);
        background-size: 1300px; // Animation Area



        &-item {
            background: #f9f9fe;
            height: 9px;
            position: absolute;
            width: 100%;
            z-index: 2;
            top: 9px;

            &:before {
                background-color: inherit;
                content: "";
                height: 9px;
                position: absolute;
                width: inherit;
                top: 19px;
            }

            &:after {
                content: "";
                position: absolute;
                background-color: inherit;
                top: 27px;
                height: 11px;
                right: 0;
                width: 65%;
            }
        }
    }

    @keyframes placeholderAnimate {
        0% {
            background-position: -650px 0;
        }

        100% {
            background-position: 650px 0;
        }
    }
    /* Single placeholder animation */
    @keyframes placeHolderShimmer {
        0% {
            background-position: -400px 0
        }

        100% {
            background-position: 400px 0
        }
    }

    .placeholder-animate-bg {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(to right, #e9e9ef 2%, #f9f9fe 18%, #e9e9ef 33%);
        background-size: 800px 104px !important;
        min-height: 12px;
        display: block;
        clear: both;
    }
    /*===============================
========== Cookies Consent ==========
===============================*/
    .cc-window.cc-floating {
        padding: 0 !important;
        background: @primary-color;
    }

    .cc-window {
        background: #354063;
    }

    .cc-message {
        width: auto;
        padding: 15px 10px;
        margin: 10px !important;
        line-height: 1.2em;
        color: #ffffff;
        font-weight: 500;
        font-size: 0.857em;
        line-height: 1.5em;
    }

    .cc-link {
        color: #ffffff !important;
    }

    .cc-btn {
        background: #323438;
        color: #ffffff !important;
        border: none !important;
        padding: 10px !important;
    }

    .cc-btn:hover,
    .cc-btn:focus {
        background-color: #3a3d41;
        color: #ffffff;
    }

    @media (max-width: 1199.98px) {
        .cc-window.cc-floating {
            max-width: 100%;
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
            display: block;
        }

        .cc-message {
            display: inline-block !important;
            width: auto;
            padding: 0 10px;
            margin: 0 !important;
            line-height: 1.5em;
        }

        .cc-link {
            padding: 1em;
        }

        .cc-compliance {
            display: inline-block;
            width: auto;
            float: right;
        }

        .cc-btn {
            display: inline-block;
            padding: 12px !important;
        }
    }

    @media (max-width: 767.98px) {
        .cc-message {
            padding: 10px;
        }

        .cc-link {
            display: block;
            padding: 1em 0;
        }

        .cc-compliance {
            display: block;
            width: 100%;
        }

        .cc-btn {
            display: block;
        }
    }

    @media (max-width: 575.98px) {
        .cc-message {
            padding: 10px;
        }

        .cc-link {
            display: block;
            padding: 1em 0;
        }

        .cc-compliance {
            display: block;
            width: 100%;
        }

        .cc-btn {
            display: block;
        }
    }
}



